export const EventDetails = [
  
  {
    id: "agri",
    title: "Agricultural Engineering",
    eventslist: [
      {
        name: "Cursed Chest Chase",
        tagline: "",
        date: "08 Nov 2024(FN)",
        type: "Team of 4",
        venue: "0872, Ground Floor, SEC",
        time: "09:00am - 12:00pm",
        rules:"Each team must consist of exactly 4 members. Teams will be selected based on tasks given before the event.The event will last for a total of 3 hours. Teams must complete the hunt within this time frame.Proper coordination between team members is essential .Teams showing signs of poor coordination may be disqualified . QR codes will be placed at specific locations as part of the game .Teams must find and scan the QR codes to progress through the hunt .A stable internet connection is required to scan the QR. It is the responsibility of each team to ensure proper connectivity, lack of internet connection will not be considered an excuse.Teams failing to follow the rules, coordinate effectively, or show up on time may face disqualification.Misconduct or cheating will lead to immediate disqualification.The event will start promptly at the designated time and location .Teams should arrive at least 15 minutes before the event begins for briefing ",      
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/cursedchest.webp",
        staff: [
          {
            name: "Er. V. Balasundaram",
            phone: 9786437197,
          },
        ],
        student: [
          {
            name: "Niranjana R",
            phone: 9962838085,
          },
          {
            name: "Salini P",
            phone: 9715999559,
          },
          {
            name: "Sinhaa Srinithi S",
            phone: 8438800293,
          },
          {
            name: "Sreya K",
            phone: 9025177044,
          },
        ],
      },//1

      {
        name: "Agriventure",
        tagline: "",
        date: "09 Nov 2024(AN)",
        type: "Team of 2-3",
        venue: "0872, Ground Floor, SEC",
        time: "01:00pm - 04:30pm",
        rules:"All participants must register before the event.Teams should consist of a 2-3 members.Participants should maintain the time limit that is given for each round.Any form of cheating or aggression, behavior may result in disqualification.Participants should arrive on time before the start of event.Using mobile phone are prohibited during the event.Any equipment is required (eg,buzzer etc), participants should handle it properly and use it as instructed.Prizes may be awarded to winners or top teams, The decision of the judges or coordinators is final.Team members should have Agriculture related team names.In the event of unforeseen circumstances (eg, weather, emergencies), games may be rescheduled or canceled, with participants notified in advance.Participants are requested to maintain respect for other participants throughout the event",  
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/agriventure.webp",
        staff: [
          {
            name: "Er. V. Balasundaram",
            phone: 9786437197,
          },
        ],
        student: [
          {
            name: "Shriya K",
            phone: 9342933171,
          },
          {
            name: "Brindha Y",
            phone: 8925480401,
          },
        ],
      },//2

      {
        name: "Clash and Conquer",
        tagline: "",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "5452, 5th Floor, SEC",
        time: "08:30am - 12:00pm",
        rules:" All participants must register in the DRESTEIN's website. On-spot registration may be limited according to the crowd. Each team may consist of 2 to 3 members. Each round will be explained before the round with clear instructions and objectives. Teams should work together to finish the round; all members should participate. Participants should not use their mobile phones in the middle of the round. If they do, their team will be disqualified. Respect for other participants should be maintained throughout the event. No offensive language or behavior will be tolerated. All rulings made by the event coordinators are final. Any objects for the event will be handed to the participants before the event. Participants should arrive on time for check-in at the designated location. Late arrivals may not be allowed to join the event. All events have time limits. All teams should finish the game within the time limit",  
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/clash.webp",
        staff: [
          {
            name: "Er. V. Balasundaram",
            phone: 9786437197,
          },
        ],
        student: [
          {
            name: "Lailathul Afrin A",
            phone: 8925171816,
          }, 
        ],
      },//3

      {
        name: "Agroblox",
        tagline: "",
        date: "08 Nov 2024(AN)",
        type: "Team of 3-4",
        venue: "5452, 5th Floor, SEC",
        time: "01:00pm - 03:00pm",
        rules:" Participants must register in advance; on-site registration may be limited or unavailable. Each team can consist of 3 to 4 members and must choose a unique name. Each round will be explained beforehand with clear instructions and objectives. Teams must work together on each challenge, with all members participating. Fair play is essential; cheating or sabotaging other teams will result in disqualification. All challenges will have time limits; failure to complete tasks within this timeframe will result in forfeiture. Respect and fairness are crucial; offensive language or behavior will not be tolerated. Decisions made by the event organizers are final; any disputes will be resolved by the event coordinator. Participants should arrive on time for check-in; late arrivals may not be allowed to join. Personal equipment should not be used without prior permission from the organizers",  
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/agroblox.webp",
        staff: [
          {
            name: "Er. V. Balasundaram",
            phone: 9786437197,
          },
        ],
        student: [
          {
            name: "Krithika D",
            phone: 9840572030,
          }, 
        ],
      },//4
    ],
    workshopslist: [
      {
        name: "Hands on Workshop - Millet based snacks and baked products",
        logo: "EventsAssets/agrdep.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        link: "https://docs.google.com/forms/d/1WO8nRxcBpBSiXzg_G0CXf_68UOKqBxzJB2eesgA0C-s/viewform?edit_requested=true",
        // company:"CFDT, TANUVAS",
        day1: {
          date: " 04 Nov 2024",
          time: "FULL DAY",
          mode: "OFFLINE",
          venue: "4871, 4th Floor, SEC",
          list: true,
          resource: [ {
            name: "Dr. N. Karpoora Sundara Pandian",
            desig:"Assistant Professor, College of Food and Dairy Technology, Chennai",
            phone: 7845938847,
          },
        
        ],
    staff: [
            {
              name: " Dr. D. Ganapathy",
              phone: 9787982231,
            },
           
          ],
          student: [
            {
              name: "Chantine S",
              phone: 8248590927  ,
            },
          ],
        },
        day2: {
          date: " 05 Nov 2024",
          time: "FULL DAY",
          mode: "OFFLINE",
          venue: "4871, 4th Floor, SEC",
          list: true,
          resource: [ {
            name: "Dr. N. Karpoora Sundara Pandian",
            desig:"Assistant Professor, College of Food and Dairy Technology, Chennai",
            phone: 7845938847,
          },
        
        ],
    staff: [
            {
              name: " Dr. D. Ganapathy",
              phone: 9787982231,
            },
           
          ],
          student: [
            {
              name: "Chantine S",
              phone: 8248590927,
            },
          ],
        },
        
      },//1
      {
        name: "Benefits, Challenges and Future of Drone in Agricultural Technology",
        logo: "EventsAssets/agri24.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSfHfmXxz77N3HMInRnFNN6yk4hYJMmDKlhuX6Griob5cLs3JQ/viewform",
        // company:"CFDT, TANUVAS",
        day1: {
          date: " 07 Nov 2024",
          time: "08:30am - 03:00pm",
          mode: "OFFLINE",
          venue: "Majestorium Hall, 5th floor, SEC",
          list: true,
          resource: [ {
            name: "Dr. K. P. Shashidar Rao",
            desig:" Founder Dronemaker India ",
          },
        
        ],
    staff: [
            {
              name: " Dr. P. Bakkiyalakshmi",
              phone: 9566670561,
            },
           
          ],
          student: [
            {
              name: "Priyadharshni. P",
              phone:  9445913039 ,
            },
          ],
        },
       
      },//2
    ],
  },//agri

  {
    id: "bme",
    title: "Biomedical Engineering",
    eventslist: [
      {
        name: "Fast Track Trivia ",
        date: " 08 Nov 2024(FN)",
        type: "Team of 3-4",
        venue: "4451, 4th Floor, SEC",
        time: " 09:00am - 12:00pm",
        rules:"Participants must compete in teams of 3 – 4 members per team.The quiz master asks questions and the first to bang the table gets to answer.No hints or additional clarifications are provided once a question is asked. Answers must be given immediately after the question is asked.The quizmaster's decision is final in case of disputes or confusion over answers. The team with the highest scores at the end of all rounds wins",
        note:"The rapid-fire quiz will incorporate SDG 9: Industry, Innovation, and Infrastructure, highlighting how biomedical engineering drives innovation and contributes to solving global health challenges through advancements in medical technology and infrastructure",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/fasttrack.webp",
        staff: [
          {
            name: "Dr. A. Swarnambiga",
            phone: 7904392157,
          },
        ],
        student: [
          {
            name: "Harsha V",
            phone: 9940203477,
          },
          {
            name: "Thangam C",
            phone: 7010039511,
          },
          
        ],
      },//1

      {
        name: " Task Trek",
        tagline: "",
        date: " 08 Nov 2024(AN)",
        type: "Team of 4",
        venue: "4681, 4th Floor, SEC",
        time: " 01:00am to 04:00pm",
        rules:"Each team must have 4 members. ⁠If one team doesn’t know the answer it will be passed to the next team.⁠If you answer the question correctly, you will be awarded one point. If your answer is wrong, the next team that answers correctly will receive 1/2 bonus points. ⁠The team with the highest score will move to the next level",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/tasktrek.webp",
        staff: [
          {
            name: "Ms. Nivethitha",
            phone: 8940499135,
          },
        ],
        student: [
          {
            name: "Janani M P",
            phone: 9962795331,
          },
          {
            name: "Vaishnavi S",
            phone: 6379568335,
          },
          {
            name: "Sruthi K",
            phone: 8344534583,
          },

          
        ],
      },//2

      {
        name: "Biosolve Blitz",
        tagline: "",
        date: " 09 Nov 2024(FN)",
        type: "Team of 3-4",
        venue: "3872 (DTE Lab), 3rd Floor, SEC",
        time: "9:00am - 12:00pm",
        // round1title: "",
        round1: "Case study will be displayed to the participants. A task will be given to each team, the team which completes the task gets the chance to answer the question.In round 1, the team should diagnose the disease in the case study. The team with maximum point will be shortlisted to Round 2",
        // round2title: "",
        round2: "In ROUND 2, each team should give an effective treatment plan of the diagnosed disease within the time limit. The team with proper explanation and treatment will be awarded",
        rules:"Participants should be on time.This event centres around SDG 3: Good Health and Well-being, focusing on global health issues, nutrition, mental health, and healthcare advancements. It aims to inspire participants to consider health improvement as a shared global responsibility",
       note:"Using mobile phones is strictly prohibited",
        
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/bioslove.webp",
        staff: [
          {
            name: "Dr. S. Sivanandam",
            phone: 9789010142,
          },
        ],
        student: [
          {
            name: "Deepika V",
            phone: 7305935667,
          },
          {
            name: "Punidha Sree S",
            phone: 9345987242,
          },
          {
            name: "Mounika P",
            phone: 7200664743,
          },

          
        ],
      },//3

      {
        name: "Escape from - The DNA Dungeon ",
        tagline: "",
        date: " 09 Nov 2024(AN)",
        type: "Team of 3-4",
        venue: "3873 (MI Lab), 3rd Floor, SEC",
        time: "9:00am - 12:00pm",
        rules:"Please arrive 10 minutes before the scheduled time for a briefing.No bags, phones, or recording devices are allowed in the escape rooms. A secure room will be provided to keep your belongings.Usage of mobile phones is strictly restricted unless instructed otherwise for referring to the internet for information.   Communication and teamwork are important in The Escape Room. While mobile phones are strictly prohibited during gameplay, open communication within your team is highly encouraged. Please be mindful of maintaining a reasonable noise level to avoid disrupting other teams.   Throughout your adventure, a dedicated Game Master will be monitoring your progress. While their presence may not be immediately apparent, they will be observing your actions and offering subtle hints if necessary.   Play with care and integrity. While enjoying the game, be mindful of rushing to other rooms to avoid injury.   Maintain respectful and ethical behavior toward others",
         round1title: "MEDIFY",
        round1: "The drawer selects a chit randomly, without revealing it to the team.The player draws the medical instrument from the chit on the board.   No letters, numbers, or symbols related to the instrument name can be used. Players are not allowed to speak or make gestures while drawing.   Teammates must guess the name of the medical instrument based on the drawing.   Teams can shout out multiple guesses until the correct answer is given.   The team moves to the next round once they have guessed all the 5 clues.    If a player uses letters, numbers, or speaks while drawing, their turn is forfeited, and their points get deducted.   If a player does not recognize the instrument on their chit, they can pass, but their team loses that turn.   Optional challenge rounds can be added where more complex instruments or anatomical tools are introduced",
        round2title: "CONNEXIONS",
        round2: "Teams are shown the first image at the start of the game.   Participants have a limited amount of time (eg, 30–60 seconds) to guess what the image represents.   Upon guessing all the images correctly, participants are given a new clue that leads to the next image or task.   Teams that fail to guess within the time limit do not move on to the next clue immediately.   Teams can opt to pass on a clue after 2 incorrect guesses, but this incurs a time penalty (eg, 1 minute added to their total time).   Team members can discuss amongst themselves, but only three tries are allowed",
        round3title: "MEDCRASH",
        round3: "The event consists of multiple sub-rounds, each with a unique instrument and a different technical fault.In each round, participants will watch a muted video showing a specific instrument with an observable issue.   After each guess, teams will receive feedback. Correct guesses grant access to additional clues or points that contribute to the final score.    Each team will have a limited time (eg, 3-5 minutes) to observe the video in each round.   After the observation, they will have another 1-2 minutes to discuss and submit their guess for the cause of the fault.   Teams can submit only one or two guesses per round.   Guesses must be concise, focusing on the specific technical fault.   No external aids or devices are allowed to research the fault during the event.   Additional clues may be granted to teams for especially challenging rounds.   Winners are determined by total points and the accuracy of the final guess and will be given the clue for the final round.   Teams are not allowed to communicate with other teams",
        round4title: "CAPTURE THE FLAG",
        round4: "Questions will be displayed one at a time on anatomy, physiology, and medical instrumentation.Teams must work quickly to solve the question and identify the correct answer.   Once they have the answer, they must signal by “capturing the flag,” which could be represented by pressing a buzzer or raising a flag.   Only the first team or individual to capture the flag may answer each question.   Participants should answer immediately upon capturing the flag. They have a limited time (eg, 30 seconds) to provide a clear, concise answer.   If the answer is correct, the team earns points. If incorrect, the question will be reopened for other participants to attempt.   Correct answers earn points based on question difficulty.   The first team to reach the predetermined target score wins the event.   In case of a tie, an additional round with a tiebreaker question will determine the winner.   Participants are not allowed to use external aids or resources during the event.   Any team that fails to answer within the time limit loses their turn for that question.   No team is allowed to communicate with other teams.   If a team is found discussing with others or using unauthorized materials, they will be disqualified from the event",
       note:"For any discrepancy, the final decision will be taken by the Game Master and Event Organizers",
        
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/escaperoomdna.webp",
        staff: [
          {
            name: "Dr. R. Helen",
            phone: 9840598158,
          },
        ],
        student: [
          {
            name: "Sherena Jenice J",
            phone: 9566533465,
          },
          {
            name: "Dharmendra A",
            phone: 9345714869,
          },

          
        ],
      },//4

    ],
    workshopslist: [
    
      {
        name: "Troubleshooting & Maintenance of Critical Care Equipments",
        logo: "/EventsAssets/bmi24.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company: "Medingers Healthcare Solutions ",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSfTN2VFkglGR36mB-xijwWUeOip2DLi-h3ZbQWdCwwG02ZPiQ/viewform ",
        day1: {
          date: "04 Nov 2024",
          time: "Full Day",
          mode: "OFFLINE",
          venue: "3873 (MI Lab), 3rd Floor, SEC",
          // topic:
          //   "Infusion Pump.Syringe Pump. Suction Pump. IFT Machine.",
          // resource: [
          //   {
          //     name: "Mr. Vinoth",
          //   },
          // ],
          staff: [
            {
              name: "Dr. Nivethitha",
              phone: 8940499135,
            },
          ],
          student: [
            {
              name: "Parvathy Ramesh",           
              phone: 7418999581,
            },
            {
              name: "Rohit R",
              phone: 9566715119,
            },
          ],
        },
        day2: {
          date: "05 Nov 2024",
          time: "Full Day",
          mode: "OFFLINE",
          venue: "3873 (MI Lab), 3rd Floor, SEC",
          // topic:
          //   "Patient Monitoring System. Electrocardiogram. Nebulizer. Fetal Doppler",
          // resource: [
          //   {
          //     name: "Mr. Vinoth",
          //   },
          //],
          staff: [
            {
              name: "Dr. Nivethitha",
              phone: 8940499135,
            },
          ],
          student: [
            {
              name: "Parvathy Ramesh",           
              phone: 7418999581,
            },
            {
              name: "Rohit R",
              phone: 9566715119,
            },
          ],
        },
      },//1
    ],
  },//bme

  {
    id: "chem",
    title: "Chemical Engineering",
    eventslist: [
      {
        name: " Heist Chronicles",
        date: "08 Nov 2024(FN)",
        type: "Team of 4",
        venue: "5611 & 5612, 5th Floor, SEC",
        time: "09:00am - 12:00pm",
        members: "Team of 4",
        round1title: "CHEM RELAY",
       round1: "Each team should consist of 4 members. Team members will perform individually to crack a letter of the word, and the 4th member will then say the final word within the allotted time. Points will be awarded for the correct word to advance to the next round",
        round2title: "CHEM PATROL",
        round2: "A setup for industrial incidents will be provided in a room. Each team will have 5 minutes to find the cause of the chemical industrial incident and collect evidence related to what happened. Afterward, the team should report to the organizer about the cause and the evidence collected. Points will be awarded for the correct identification of the root cause and appropriate solutions. Based on the points earned, the winner, 1st runner-up, and 2nd runner-up will be selected",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/heistchronicles.webp",
        staff: [
          {
            name: "Dr. N. Umaiyambika",
            phone: 9444945153,
          },
        ],
        student: [
          {
            name: " Nizar Khan A",
            phone: 8637406706,
          },
          {
            name: "Mithun V",
            phone: 9025014732,
          },
        ],
      },//1

      {
        name: " Wasser Wars",
        date: "09 Nov 2024(FN)",
        type: "Team of 3",
        venue: "5611 & 5612, 5th Floor, SEC",
        time: "09:00am - 12:00pm",
        round1title: "ChemIQ Clash",
        round1: "Questions will be displayed on the mentimeter website, and teams should attend the quiz through the website. Each question will be awarded points by the website, and the top 20 teams with the highest points will qualify for the next round",
        round2title: "PICTIONARY",
        round2: "Pictures will be shown to the teams, with each picture representing a word that, when combined, will form a technical term. The game follows a buzzer system. The team that identifies the most words will qualify for the next round",
        round3title: "Wasser Wars",
        round3: "Each qualified team will be provided with dirty water. The team's task is to analyze the materials provided to treat the dirty water and arrange or build a better water filtering unit. The time limit for this round is 30 minutes. Each material will have its unit prices, which will reduce the team's credit points accordingly when purchased. Each team has a credit limit to buy items from the organizer. The qualified teams should aim to produce the best quality and lowest TDS (Total Dissolved Solids) water",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/wasserwars.webp",
        staff: [
          {
            name: "Dr. A. Kumar",
            phone: 9500651760 ,
          },
        ],
        student: [
          {
            name: " Rishanth S",
            phone: 6382102344,
          },
          {
            name: "Venmitha M",
            phone: 8122492076,
          },
        ],
      },//2

      {
        name: " Ultimate ShowOff",
        date: "08 Nov 2024(FN)",
        type: "Team of 3-4",
        venue: "5613 & 5652, 5th Floor, SEC",
        time: "10:00am - 12:00pm",
        round1title: "AIM AND CLAIM",
        round1: "Each team is provided with six darts to use during the game. After throwing the darts on the board, questions are asked. The difficulty of the questions is determined by where the team's darts landed in relation to the center of the dartboard, with points highest for darts that hit the center and decreasing as they move outward. Teams answer questions based on the points they achieve with their dart throws",
        round2title: "BATTLEOF BIDS",
        round2: "Teams must first answer five questions to earn points (money) for the auction. Correct answers earn teams a high number of points, while other teams receive points based on the number of questions they answered. After earning points, an auction begins where teams can bid their points to answer questions.  Teams can only bid points they have earned and must know the answer to place a bid. The team with the highest number of correct answers during the auction qualifies for the next round",
        round3title: "NEWS SEEKER",
        round3: "Each team receives a clip from a newspaper about a recent industrial accident. They are provided with a printed sheet containing around ten questions related to the incident. Teams must complete their answers within a specified time limit and are judged on both the speed and accuracy of their responses. The fastest team to accurately answer the questions is declared the winner",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/ultimateshowoff.webp",
        staff: [
          {
            name: "Dr. P. Senthil Kumar",
            phone: 7305105056 ,
          },
        ],
        student: [
          {
            name: " Anish Kumar A ",
            phone: 8610124025,
          },
          {
            name: "Agalya T",
            phone: 9840728167,
          },
          {
            name: "Keerthivarman A",
            phone: 7806993962,
          },
          
        ],
      },//3

      {
        name: " Brainy Browl",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "5613, 5652, 5654, 5th Floor, SEC",
        time: "09:00am - 12:00pm",
        members: "Team of 2-3",
        round1title: "CHEM E WHEEL OF FORTUNE",
        round1: "This event features a spinning wheel divided into six distinct sections. A representative from each team will spin the wheel, and based on the outcome, they will participate in the corresponding game. Each team will be allotted a specific time limit according to the game to complete their challenge",
        round2title: "ANALYZE THE LIQUOR SAMPLE",
        round2: "Teams are arranged based on scores from the previous round. Each team will receive a beverage sample, and there will be two types of lab tests. According to the lot drawn, teams will conduct the lab tests to identify whether ethanol or glucose is present in the given sample. The procedure and required reagents will be provided. The first three teams to correctly identify the contents will be selected for the next round",
        round3title: "FIND THE CULPRIT CHEMICAL",
        round3: "Different types of chemical poisons are present in the loot. Players in each team need to pick a loot item, and based on the name of the chemical poison they receive, they must find the clues (specifically, the letters in that word) that are hidden in a room. The team that completes the task first will be declared the winner, while the following teams will be designated as runners-up",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/brainybrowl.webp",
        staff: [
          {
            name: "Dr. S. Balachandran",
            phone: 8610979386,
          },
        ],
        student: [
          {
            name: " Harishankar E",
            phone: 8807051192,
          },
          {
            name: "Shruthi Sharma",
            phone: 6379256066,
          },
        ],
      },//4
  
    ],
    workshopslist: [
      {
        name: "ASPEN Plus Insight - Modeling Reactor & Distillation Processes",
        logo: "EventsAssets/aspen.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        link: "https://forms.gle/DNPaR24M5j44j6Lw5",
        day1: {
          date: "05 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "OFFLINE / Individual",
          venue: "5411 (PIDC Lab), 5th Floor, SEC",
          // topic:"Section 1:Configuring the Property Environment in Aspen Plus. Section 2:Overview of using modern palette in Aspen Plus",
          resource: [
            {
              name: "Dr. A. Balasubramanian",
              desig: "Associate Professor - SEC"
            },
            {
              name: "Dr. P. Sundar Rajan",
              desig: "Assistant Professor - SEC"
            },
          ],
          staff: [
            {
              name: "Dr. A. Balasubramanian",
              phone: 9488478235,
            },
          ],
          student: [
            {
              name: "Dinesh S",
              phone: 9345580512,
            },
            {
              name: "Vishnupriyan R",
              phone: 8111083313,
            },
            {
              name: "Kathirlingam V",
              phone: 9894931232,
            },
            {
              name: "Shaveena Wershi G S",
              phone: 7904198654,
            },
          ],
        },
        day2: {
          date: "06 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "OFFLINE / Individual",
          venue: "5411 (PIDC Lab), 5th Floor, SEC",
          topic:"Case study on usage of Distillation Column. Section 2:Case study on usage of Reactor Column",
          resource: [
            {
              name: "Dr. N. Umaiyambika",
              desig: "Assistant Professor - SEC"
            },
            {
              name: "P. Sundar Rajan",
              desig: "Assistant Professor - SEC"
            },
          ],
          staff: [
            {
              name: "Dr. A. Balasubramanian",
              phone: 9488478235,
            },
          ],
          student: [
            {
              name: "Dinesh S",
              phone: 9345580512,
            },
            {
              name: "Vishnupriyan R",
              phone: 8111083313,
            },
            {
              name: "Kathirlingam V",
              phone: 9894931232,
            },
            {
              name: "Shaveena Wershi G S",
              phone: 7904198654,
            },
          ],
        },    
      },//1
    ],
  },//chem

  {
    id: "civil",
    title: "Civil Engineering",
    eventslist: [
      {
        name: "Voice, Vision, Victory",
        date: "08 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "0732, Ground Floor, SEC",
        time: "09:00am - 12:00pm",
        round1title: "WHISPER AND WIN",
        round1: "Form teams with one Guesser per round while others whisper a sentence.Team members quietly whisper the same sentence without gestures or sounds.The Guesser has 1 minute to guess the whispered sentence.Points are awarded based on guessing speed: 0-20 sec (5 points), 21-40 sec (3 points), 41-60 sec (1 point).Rotate roles each round, and the team with the highest points at the end wins",
        round2title: "GRAB AND GUESS",
        round2: "Participants must wear a blindfold or eye mask at all times.Once an object is identified, participants must announce their answer immediately.Participants are allowed a maximum of 2 hints throughout the game.Correct answers earn 10 points; incorrect answers yield no points.Participants may not receive external assistance.The Guesser has 90 seconds to guess the object",
        round3title: "TRASH TO TREASURE",
        round3: "Time Limit: 30 minutes.Waste Material Collection:.Teams must collect waste materials from around the college.Materials must be collected within the 30-minute time frame.Teams must return to the event venue within 30 minutes.Craft Creation:.Dimensions for the craft will be provided by organizers on the spot.Teams must create their craft within the given dimensions.Only waste materials collected during the challenge can be used.No external materials or tools allowed (except for basic crafting tools like glue, scissors, etc)",
        
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/voicevision.webp",
        staff: [
          {
            name: "Dr. S. Theerkadharshini",
            phone: 9597594405,
          },
        ],
        student: [
          {
            name: "Samanth Priyan V S",
            phone: 9841013469,
          },
          {
            name: "Lahithya C",
            phone: 7095804159,
          },
          {
            name: "Mariya Andrea L",
            phone: 9360846766,
          },
        ],
      },//1

      {
        name: " Cup - Titude Frenzy",
        date: "08 Nov 2024(FN)",
        type: "Team of 2",
        venue: "0731, Ground Floor, SEC",
        time: "10:00am - 12:00pm",
        round1title: "Flip and Win",
       round1: "A team includes 2 members. Teams must design a device using rubber bands, popsicle sticks, and cardboard to flip a paper cup upside down. After successfully flipping the paper cup, it should be placed on the Tic-Tac-Toe board. The first team to get three of their cups in a row (up, down, across, or diagonally) qualifies for the next round",
        round2title: "Pyramid Zenith",
        round2: "Each team will be given 1 cup of sand and 15 paper cups. A pyramid with 5 layers must be constructed within 10 minutes. The pyramid should have the stability to withstand 3 shakes",
        round3title: "Cupstruct",
        round3: "Each team will be given 50 paper cups. A civil structure must be built within 20 minutes",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/cuptide.webp",
        staff: [
          {
            name: "Ms. S. Yamini Roja",
            phone: 7010432801,
          },
        ],
        student: [
          {
            name: "Barkavi D ",
            phone: 8807176623,
          },
          {
            name: "Sawasthika G P",
            phone: 7397621996,
          },
          {
            name: "Srinivas P K",
            phone: 9940497207,
          },
        ],
      },//2

      {
        name: " BridgeIt",
        date: "09 Nov 2024(FN)",
        type: "Team of 2",
        venue: "0732, Ground Floor, SEC",
        time: "N/A",
        rules: "Rules for Round 1:  Each team consists of two members. Question Format : Questions about bridges and famous structures will be displayed on a screen. Response : Teams must write their answers on sheets provided. Judging will be based on the accuracy of responses, and the top teams will qualify for the next round. Round 2: London Bridge Is Falling Down (Score: 70 marks) .  This round is a hands-on challenge where participants will construct a truss-type bridge. Materials : Each team will be given a limited supply of newspapers, along with glue and rubber bands to build their bridge. Structure : The bridge should be a truss type and must meet specific dimension requirements provided at the start of the round. Testing : The constructed bridges will undergo a load test to assess their strength, as well as a dimension test to ensure they meet the set criteria. Winner Selection : The teams whose bridges pass both the load and dimension tests will be declared the winners ",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/bridgeit.webp",
        staff: [
          {
            name: "Dr. S. Padma",
            phone: 9445452883,
          },
        ],
        student: [
          {
            name: "Prakalya C A ",
            phone: 9342479383,
          },
          {
            name: "Gokula Krishnan N",
            phone: 9940363039,
          },
          {
            name: "Pugazh Puviyalan J M",
            phone: 7010560852,
          },
        ],
      },//3
    ],
    workshopslist: [
      {
        name: "Digital Land Survey 2.0",
        logo: "EventsAssets/digitalland.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "5731, 5th Floor, SEC",
        time: " 10:00 AM to 02:00 PM",
        note:"Laptop Required",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSdy2FVMZuXcsbdRudTLJlM-nnmOixNjrHlNOE7s7ubhLy5oaQ/viewform",
        
        day1: {
          date: "04 Nov 2024",
          time: "10:00 AM to 02:00 PM",
          mode: "Individual",
          venue: "will be updated soon",
          resource: [
            {
              name: "Mr. S. Sangili Uddayappan",
              desig: "COE, Chief Surveyor",
            },
            
          ],
          staff: [
            {
              name: "Dr. G. Venkatesan",
              phone:  9789290995,
            },
          ],
          student: [
            {
              name: "Sneharajan S",
              phone:  9940643218,
            },
            {
              name: "Jayasri N",
              phone: 9677221289,
            },
            {
              name: "Madhumitha S",
              phone: 8015646347,
            },
          ],
        }, 
        day2: {
          date: "05 Nov 2024",
          time: "10:00 AM to 02:00 PM",
          mode: "Individual",
          venue: "will be updated soon",
          resource: [
            {
              name: "Mr. S. Sangili Uddayappan",
              desig: "COE, Chief Surveyor",
            },
            
          ],
          staff: [
            {
              name: "Dr. G. Venkatesan",
              phone:  9789290995,
            },
          ],
          student: [
            {
              name: "Sneharajan S",
              phone:  9940643218,
            },
            {
              name: "Jayasri N",
              phone: 9677221289,
            },
            {
              name: "Madhumitha S",
              phone: 8015646347,
            },
          ],
        },      
      },//1
      {
        name: "Modern Solutions for Bill of Quantities in Residential Construction",
        logo: "EventsAssets/mordernsol.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: " 10:00am - 02:00pm",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSdqfxUvkHTBgIQn5hCtihVSSH8vOFw_bnqsFpHqIUqfgJxP4Q/viewform",
        
        day1: {
          date: "06 Nov 2024",
          time: "10:00am - 02:00pm",
          mode: "Individual",
          venue: "will be updated soon",
          resource: [
            {
              name: "Mr. K. Mohammed Asif",
              desig: "CEO, M Cube Construction and Promotors",
            },
            
          ],
          staff: [
            {
              name: "Dr. M. Madhan Kumar",
              phone:  7708754085,
            },
          ],
          student: [
            {
              name: "Asif S",
              phone:  6383842405,
            },
            {
              name: "Arul C",
              phone: 9342597112,
            },
            {
              name: "Nithishkumar J",
              phone: 9566165318,
            },
          ],
        }, 
        day2: {
          date: "06 Nov 2024",
          time: "10:00am - 02:00pm",
          mode: "Individual",
          venue: "will be updated soon",
          resource: [
            {
              name: "Mr. K. Mohammed Asif",
              desig: "CEO, M Cube Construction and Promotors",
            },
            
          ],
          staff: [
            {
              name: "Dr. M. Madhan Kumar",
              phone:  7708754085,
            },
          ],
          student: [
            {
              name: "Asif S",
              phone:  6383842405,
            },
            {
              name: "Arul C",
              phone: 9342597112,
            },
            {
              name: "Nithishkumar J",
              phone: 9566165318,
            },
          ],
        },
            
      },//2
    ],
  },//civil

  {
    id: "ece",
    title: "Electronics and Communication Engineering",
    eventslist: [
      {
        name: "Circuit Circus",
        date: "08 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "3412, 3rd Floor, SEC",
        time: "09:00am - 12:00pm",
        round1title: "Counter Strike",
        round1: "Teams will solve visual puzzles projected on screen, with each image offering clues to electronics-related terms. Participants analyze images to uncover concepts or components in electronics. Points are awarded for each correct answer, and high-scoring teams advance to the next round. Sharpen your observation skills and think creatively to solve these electronic-themed puzzles!",
        round2title: "Flip & Match",
        round2: "Participants play a memory challenge by flipping cards to find matching pairs. Each player takes turns flipping two cards, aiming to match images, symbols, or numbers. A match allows them to keep the pair and continue; if not, the cards are flipped back, and the next player goes. The goal is to collect the most pairs, testing memory, focus, and quick thinking",
        round3title: "Circuit Craze",
        round3: "Participants begin by scanning one of six QR codes, each linking to a quiz with ten core / general topic questions. Scoring at least 7 out of 10 qualifies them for the next stage, where they proceed to the table matching their QR code number. At each table, five new QR codes lead to various challenges, including a circuit debugging task where teams identify and fix issues in a circuit diagram",
        // note:"",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/circuitcircus.webp",
        staff: [
          {
            name: "Dr. S. Kanagamalliga",
            phone: 7708662408,
          },
          {
            name: "Dr. A. J. Heiner",
            phone: 9489393913,
          },
        ],
        student: [
          {
            name: "Aravinth N",
            phone: 9025420055,
          },
          {
            name: "Jaya Vishal M",
            phone: 8925342114,
          },
          {
            name: "Dhinesh S",
            phone: 9150231058,
          },
          {
            name: "Adhavan K",
            phone: 9445961562,
          },
        ],
      },//1

      {
        name: "Tech Tonix",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "3411, 3rd Floor, SEC",
        time: "09:00an - 12:00pm",
        round1title: "Rapid Circuit Recall",
        round1: "In this round, a circuit will be shown, learners have to memorize the circuit in 10 minutes and questions will be asked from that . Teams will get points for each questions they answer correctly.Display the circuit diagram for 10 minutes, ensuring it's clear, visible, and accessible to all learners. During this 10 - minute window, learners should memorize the circuit components, connections, and overall design. After the display period, questions will be asked related to the circuit. Award points for each correct answer. Consider bonus points for consecutive correct answers or penalty points for incorrect ones",
        round2title: "Circuit Fix-It",
        round2: "Learners are provided with an incorrect circuit diagram. They must debug and rectify the diagram, simulate it using Tinkercad, and present the output.Participants are required to identify and rectify errors in the given diagram. These errors might include incorrect component values, faulty connections, or missing components. Once the corrections are made, participants will simulate their corrected circuits using Tinkercad",
        round3title: "Magic Box",
        round3: "Create a functional electronic project within 2 hours using mystery components. Materials - Mystery box containing: - Sensors (ultrasonic, temperature, light) - Actuators (LED, buzzer, motor) - Power supply - Breadboard - Jumper wires - Miscellaneous components (resistors,capacitors, etc).There will be a set of boxes containing circuit diagrams. Participants will pick one circuit diagram from the box. After selecting the circuit, they will need to choose the correct components from another box to ensure the circuit produces the desired output. The task is to select the components correctly and demonstrate the output. The first three teams to successfully show the output will be declared the winners",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/techtonix.webp",
        staff: [
          {
            name: "Dr. Raji Pandurangan",
            phone: 9003051625,
          },
          {
            name: "Dr. T. Jaya",
            phone: 9488882262,
          },
        ],
        student: [
          {
            name: "Esaki Muthu E ",
            phone: 7358596661,
          },
          {
            name: "Bharth Kumar G",
            phone: 8056048552,
          },
          {
            name: "Ragupathi Raj M",
            phone: 9360317009,
          },
          {
            name: "Pavithra S",
            phone: 8925274865,
          },
        ],
      },//2

      {
        name: "Electrox Chaos",
        date: "09 Nov 2024(FN)",
        type: "Team of 2",
        venue: "3731, 3rd Floor, SEC",
        time: "10:00am - 02:00pm",
        round1title: "CLUE TREKS",
        round1: "Team members face opposite directions, each with a set of clue cards. Players use the clues to identify the jumbled component names on their cards. Points are awarded based on the number of clues used to identify each component. The top teams from Round 1 will advance to the circuit design showdown",
        round2title: "KALEIDOSCOPE",
        round2: "One team member will draw a chit from a bowl containing circuit names and components. The team member must provide verbal clues to their partner about the circuit and its components without naming them. The partner will then use Tinkercad to design the circuit based on the clues provided. The teams will submit the design, which is scored based on accuracy and speed",
        round3title: "SILICON SILLINESS",
        round3: " The top 5 teams compete to deliver the funniest stand-up comedy related to electronics or communication that describes your life. Their innovative ideas and audience laughter determine the winners",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/electroxchoas.webp",
        staff: [
          {
            name: "Dr. M. Sowmiya Manoj",
            phone: 7358747803,
          },
          {
            name: "Ms. V. Subashree",
            phone: 9994365720,
          },
        ],
        student: [
          {
            name: "Sachita R",
            phone: 8939668150,
          },
          {
            name: "Sowmithra R",
            phone: 6379251663,
          },
          {
            name: "Reshma S",
            phone: 9884158025,
          },
        ],
      },//3

      {
        name: "Coder's Clash ",
        date: "09 Nov 2024(AN)",
        type: "Team of 2",
        venue: "3613, 3rd Floor, SEC",
        time: "10:00am - 01:00pm",
        round1title: "CODE HUNT",
        round1: "Story-Driven Challenges: Participants engage with a captivating narrative while answering multiple-choice questions on computer fundamentals, C, and Python. Unlock the Map: Each correct answer reveals clues that piece together a treasure map leading to the hidden Code Crystal",
        round2title: "CODE RELAY",
        round2: "Each team is presented with a real-life coding scenario. Once the briefing ends, background music will start playing to make the experience more dynamic. The first participant begins coding the initial part of the solution. After 2-3 minutes, the first participant passes the code to the second team member, who takes over from where the first left off",
        round3title: "DEBUG TO DISCOVER",
        round3: "Bug Identification: Teams receive a code snippet with errors and work together to analyze and debug it to find the mistakes. Unlocking Clues: Once the code is corrected, it reveals hidden clues that help teams decipher a secret key. Treasure Hunt: The final key points to a treasure location, and the first team to find and retrieve the hidden item wins the challenge",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/codersclash.webp",
        staff: [
          {
            name: "Dr. S. Imran Hussain",
            phone: 9003399350,
          },
          {
            name: "Dr. K. Nirmala Devi",
            phone: "9677781647",
          },
        ],
        student: [
          {
            name: "Geerthivash J D",
            phone: 9043258972,
          },
          {
            name: "Suganth S",
            phone: 8870436377,
          },
          {
            name: "Tamilselvan J",
            phone: 9080223297,
          },
          {
            name: "Thenmozhi N",
            phone: 9344088545,
          },
        ],
      },//4

      {
        name: "Silicon Valley Showdown ",
        date: "09 Nov 2024(AN)",
        type: "Team of 3-4",
        venue: "3412, 3rd Floor, SEC",
        time: "10:00an - 04:00pm",
        round1title: "The Circuit Challenge",
        round1: "Teams will recreate a circuit diagram accurately based on audio instructions. All teams listen to instructions twice via a central audio setup. Teams draw their circuits based on what they remember from the audio instructions. 1 point is given for the completeness of the circuit path",
        round2title: "The Logic Labyrinth – Digital Logic",
        round2: "Questions will be based on digital logic concepts, requiring logical reasoning and binary calculations. Teams will submit their final answers on the event webpage for evaluation. Full points are awarded for each correct final answer submitted by the teams. Multiple incorrect attempts lead to deduction of points",
        round3title: "Clash of Calculations",
        round3: "Hints are available at varying point costs: 5, 10, 15 points. Answering correctly without hints earns teams an additional 10 bonus points. The team with the most correct answers wins the auction round. In case of a tie, the remaining points or sudden – death questions will determine the winner",
        note:"Participants are advised to bring their own AirPods or earphones for an enhanced experience during the event.Winning teams will receive exclusive bookmarks inspired by Sustainable Development Goals (SDGs) as prizes!",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/siliconvalley.webp",
        staff: [
          {
            name: "Dr. N. Sugitha",
            phone: 9442077420,
          },
          {
            name: "Ms. N. Chitra",
            phone: 9751232451,
          },
        ],
        student: [
          {
            name: "Kiruthika M",
            phone: 8667590493,
          },
          {
            name: "Boomika K",
            phone: 6379386560,
          },
          {
            name: "Rehan Naveid R",
            phone: 6374333934,
          },
        ],
      },//5

      {
        name: "Spark & Search - The Electronics Adventure",
        date: "08 Nov 2024(FN & AN)",
        type: "Team of 2-3",
        venue: "3411, 3rd Floor, SEC",
        time: "10:00am - 03:00pm",
        round1title: "Matoran Puzzle",
        round1: "Students will first answer thought-provoking MCQs focused on fundamental electronics concepts. Each answer is a single-word term related to electronics. The answers will be hidden within a crossword puzzle filled with Matoran alphabet instead of regular letters. Using a Matoran alphabet key, participants must find and decode each correct answer within the puzzle",
        round2title: "Boolean Sudoku",
        round2: "Participants solve a 4x4 sudoku divided into four numbered 2x2 subgrids.Each sub grid has a unique logic diagram with two 2-input gates, whose outputs provide 2-bit answers to fill each line of the sub grids. They must ensure that 2-bit entries don’t repeat within any row, column, or 2x2 sub grid",
        round3title: "Mind Rush",
        round3: "Each team will select a storyline number and receives clues via WhatsApp to find the specific classrooms. Volunteers in the class rooms verify answers and ask questions related to electronics; correct responses will earn a component picture. Aftercollecting 5 pictures, teams will have to assemble a circuit diagram at the final venue. The fastest 3 teams win the Spark &amp; Search title!",  
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/saprkandsearch.webp",
        staff: [
          {
            name: "Dr. J. Jeffin Gracewell",
            phone: 8903280025,
          },
          {
            name: "Dr. K. Mahendran",
            phone: 9698999699,
          },
          
        ],
        student: [
          {
            name: "Subashini  S",
            phone: 8220282898,
          },
          {
            name: "Vijayalakshmi B",
            phone: 8148444700,
          },
          {
            name: "Kameshwari M",
            phone: 6374942860,
          },

        ],
      },//6

      {
        name: "Tech-Bid Arena",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "3512, 3rd Floor, SEC",
        time: "10:00am - 01:00pm",
        round1title: "Components Insight",
        round1: "In Component Identification, teams identify electronic components and understand their functions. For Data Analysis, teams analyze component characteristics like resistance, capacitance, and voltage. Strategic Planning involves teams strategizing component prioritization based on circuit needs and budget",
        round2title: "Bid and Build",
        round2: "In Component Selection, teams choose components based on their performance in Round 1. For Budget Management, teams receive a limited budget and must strategize their spending. In Competitive Bidding, teams participate in a bidding process to acquire essential components for circuit design. After the bidding, teams proceed to build a circuit using the components they have won",
        round3title: "Circuit Masters [Rapid Adaptation]",
        round3: "In New Component Integration, teams receive additional components that must be incorporated into the circuit built in the previous round. During Functional Adaptation, teams modify their circuits to include the new components while ensuring that the original circuit's primary function is preserved",

        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/techbidarena.webp",
        staff: [
          {
            name: "Dr. S. Asha",
            phone: 9443597837,
          },
          {
            name: "Ms. A. Hemamalini",
            phone: 9952648240,
          },
        ],
        student: [
          {
            name: "Thiyaga Sri Charumathy",
            phone: 6385039721,
          },
          {
            name: "Trisha S",
            phone: 9043331015,
          },
        ],
      },//7

      {
        name: "Wired 2 Breakbad",
        date: "08 Nov 2024(FN)",
        type: "Team of 2",
        venue: "3481 & 3482, 3rd Floor, SEC",
        time: "10:00am - 01:00pm",
        round1title: "CIRCUIT CHEMISTRY",
        round1: "In this challenge, participants are presented with a faulty circuit design in Tinkercad. The task is to identify and correct the errors within a set time limit. Throughout the challenge, participants earn points for every correct solution. At the end of the round, teams with the highest total points advance to the next round. The number of teams advancing to the final round will be determined based on the registration count",
        round2title: "COOK THE CODE",
        round2: "Participants are provided with a secret message encoded in binary format. They must decode the message within a given time limit by converting it into an understandable word. The decoding format involves converting the binary input into ASCII values to reveal the secret message. To make the event more engaging and informative, participants will also take part in in-game fun activities to earn clues and hints for decoding the message. The top three teams that decode the message the fastest will be declared the winners",
        note:"All must bring laptop",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/wiredtwobreakbad.webp",
        staff: [
          {
            name: "Dr. P. Vinayagam",
            phone: 9942534757,
          },
          {
            name: "Mr. Valvilori",
            phone: 8056371716,
          },
        ],
        student: [
          {
            name: "Vishnu P",
            phone: 8148976885,
          },
          {
            name: "Vishal K",
            phone: 9551155577,
          },
        ],
      },//8

      {
        name: "Electroholic",
        date: "09 Nov 2024(AN & FN)",
        type: "Team of 1-3",
        venue: "3511, 3rd Floor, SEC",
        time: "10:00am - 01:00pm",
        round1title: "Grab and Link",
        round1: "Test your electronics skills through a fun drag and drop activity. Drag the appropriate components to get the desired output of the circuit",
        round2title: "Unravel the pdf enigma",
        round2: "Unlock a password-protected PDF using clues. Decode the password from the provided clues to access the PDF. Each letter of the password will be decoded using given technical clues(Image/word) Use the clues to determine the password within [time limit, eg, 15 minutes]",
        round3title: "circuitry code clash",
        round3: "In this round, the first five participants who successfully unlock the PDF will advance to the next stage. Their challenge is to implement the specified circuit and write the code to achieve the desired functionality. All necessary components for circuit building and testing will be provided. Participants will receive a specific circuit schematic and must connect the components as per the design, followed by writing the required code to control the circuit's functions",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/elect.webp",
        staff: [
          {
            name: "Dr. R. Vinod kumar",
            phone: 9865657392,
          },
          {
            name: "Dr. Kumaran",
            phone: 9994958058,
          },
        ],
        student: [
          {
            name: "Naresh Kumar R S",
            phone: 8838348595,
          },
          {
            name: "Sughesh Kumar E",
            phone: 9159644663,
          },
          {
            name: "Ugendran R",
            phone: 8056612485,
          },
        ],
      },//9

      {
        name: "Line Follower Robot",
        date: "08 Nov 2024(FN & AN)",
        type: "Team of 10",
        venue: "3652, , 3rd Floor, SEC",
        time: "09:00am - 03:00pm",
        round1title: "Bot - Assemble",
        round1: "The teams will be studying and assembling the robot. Every teams gets the code and the components and each team should have a laptop to dump the code which is given. Every team should design their robot, they can use their own design based on their Creativity to assemble the robot. They get about 2 hours to assemble and design the robot. After they designed their model, they can participate in the Rounds",
        round2title: "Maze Curve",
        round2: "The track is made difficult, so that the round will be more complex. The selected 5 teams will play round 2. Similarly, here also the robot should reach the end line without any interruptions. This maze pattern is time based task, where the teams will play the task with 5minutes in their hand. The robot which reach the endline with less time is the winner",
        note:"All must bring laptop with Arduino IDE installed",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/linefollow.webp",
        staff: [
          {
            name: "Dr. K. Chandrasekharan",
            phone: 9444462663,
          },
          {
            name: "Mr. M. Praveen",
            phone: 8807592640,
          },
        ],
        student: [
          {
            name: "Vikram G S",
            phone: 6381200080,
          },
          {
            name: "Niraimathiyan T V",
            phone: 8248942362,
          },
          {
            name: "Srijayan T",
            phone: 9791260201,
          },
          {
            name: "Venakatasubramanium R",
            phone: 9994966572,
          },
        ],
      },//10
      
      {
        name: "Gusto",
        date: "08 Nov 2024(FN)",
        type: "Team of 3",
        venue: "3653 & 3654, 3rd Floor, SEC",
        time: "09:00am - 12:00pm",
        members: "Team of 3",
        round1title: "ELECTRO PUZZ",
        round1: "Electro puzzle consists of a grid, with each box in the grid designated to one letter. The clues are numbered from 1 across and 1 down to signify which direction the word goes. Answers must fit within the allotted space given for the clue. Words in the crossword puzzle will cross each other. The goal is to solve every clue and enter every letter and word on the grid",
        round2title: "Roll The Dice",
        round2: "Each team designates any one player to roll the dice. A game board with numbered boxes (corresponding to dice numbers) is prepared in advance. Each numbered box contains either a question, a cross mark (elimination), or a tick mark (extra roll). The team that scores higher points will be selected to the next round",
        round3title: "Blind - Find",
        round3: "The goal of Blind Find is for the blindfolded player to memorize the given diagram in 30 seconds. The blindfolded player can ask for guidance from their teammate and needs to connect the circuits according to the diagram. There is a time limit for completing the connections, and the team that almost completes or fully completes within the time limit will be the winners",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/gusto.webp",
        staff: [
          {
            name: "Dr. P. Baraneedharan",
            phone: 9443648262,
          },
          {
            name: "Dr. K. Indhumathi",
            phone: 9940453582,
          },
        ],
        student: [
          {
            name: "Dhivyadharshini S",
            phone: 8778330788,
          },
          {
            name: "Kamali S",
            phone: 9361038619,
          },
          {
            name: "Devika N",
            phone: 8056771333,
          },
        ],
      },//11

      {
        name: "QuadCon",
        date: "09 Nov 2024(FN & AN)",
        type: "Team of 3-4",
        venue: "Outdoor - near Saveetha stores, SEC",
        time: "09:00am - 03:00pm",
        rules: "Participants are given a race track that they must complete. The tracks can be outdoor, featuring LED gates, flags, tunnels, and hoops to navigate. Pilots must maintain control of the quadcopter while navigating tight spaces and making split-second decisions. Drones used for the race course can have either an RC controller or a real-time operating system with an advanced flight controller, which is up to the participant. Participants can form a team comprising 2-3 members. The drone must not fly more than 4 meters in the air, as violating this could be a fatal mistake impacting their results",
        note:"The drone contesting in the race course must weigh within 1 kg 35 gms. The pilot operating the quadcopter must have some previous experience with operating drones, which will be checked by technical experts before the race starts. All pilots must follow the race rules; any violation of the rules will result in immediate disqualification of the participant.As mentioned, the race course can be indoor or outdoor. The track may be narrower, which will determine the level of capability and robustness required from the drone. There can be sections of the track where the drone is restricted to an altitude of 75 cm or even lower. Some sections will test the drone's ability to move both forward and backward. Hitting any obstacles will incur penalties, such as added time to your overall race time. Finally, upon completion of the race course, the drone must land within a specified region for the timer to stop",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/quadcon.webp",
        staff: [
          {
            name: "Dr. R. Latha",
            phone: 9940362221,
          },
          {
            name: "Dr. R. Arasakumar",
            phone: 9894914225,
          },
        ],
        student: [
          {
            name: "Dhayanithi S",
            phone: 8825454463,
          },
          {
            name: "Melvinraj G",
            phone: 7010894262,
          },
          {
            name: "Bharath M",
            phone: 9677212244,
          },
          {
            name: "Jothivanan T",
            phone: 8148842579,
          },
        ],
      },//12

    ],
    
    workshopslist: [
      {
        name: "Boot Camp on Drone Technology",
        logo: "EventsAssets/bootcamp.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "08.00am - 03:00pm", 
        note: "All participants must bring Laptop. Conducted By Department of ECE, EEE, SCOFT [AIDS | AIML | CSE | IT]",
        link: "https://docs.google.com/forms/d/1bgT9d1CYHXoVtNS37p0mHhrZhgNdXthS0F1sGNr7wQE/viewform?edit_requested=true",
        day1: {
          date: "04 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "Lines Hall, 3rd Floor, SEC",
          topic:"Introduction to Drones – Overview of types, components, and applications.",
          resource: [
            {
              name: "Dr. K. Krishna Naik",
              desig: "Associate Professor & HOD, IIITDM, Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910",
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
           
          ],
        },
        day2: {
          date: "05 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "Majestorium Hall, 5th Floor, SEC",
          topic:"Flight Mechanics – Basics of aerodynamics, flight control, and stability.",
          resource: [
            {
              name: "Dr. K. V. Eswaramoorthy",
              desig: "Assistant Professor (Grade-1) IIITDM, Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910",
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
          ],
        },
        day3: {
          date: "06 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "will be updated soon",
          topic:"Programming and Sensors – Integrating sensors, GPS, and control software.",
          resource: [
            {
              name: "Dr. K. Nagaraju",
              desig: "Assistant Professor (Grade 1), IIITDM, Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910",
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
          ],
        },
        day4: {
          date: "07 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "will be updated soon",
          topic:"Autonomous Navigation – Path planning, obstacle detection, and AI for autonomy.",
          resource: [
            {
              name: "Mr. D. Akhil Kumar",
              desig: "Junior Research Fellow,  IIITDM,  Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910",
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
          ],
        },
        day5: {
          date: "08 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "will be updated soon",
          topic:"Safety and Regulations – Key rules, privacy, and ethical considerations.",
          resource: [
            {
              name: "Ms. A. Sai Jahnavi",
              desig: "Junior Research Fellow, IIITDM,  Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910" ,
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
          ],
        },
      },//1
      
      {
        name:" Industrial Automation Using PLC and ROBOTS",
        logo: "EventsAssets/industrialautomation.webp",
        company:"AXIS GLOBAL INSTITUTE OF INDUSTRIAL TRAINING (AGIIT)",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "FULL DAY", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSdvQQgJ1RURc14Tg8_6L3a8kan5w78dxtAUe_C0ZMLXPI56Ig/viewform",
        note: "All participants must bring Laptop",
        day1: {
          date: "04 Nov 2024",
          time: "09:00am - 03:00pm",
          mode: "Individual",
          venue: "3613, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Vignesh Ramadoss",
              desig: "Technical Trainer",
            },
          ],
          staff: [
            {
              name: " Dr. Srigitha S. Nath", 
              phone: 9986822498,
            },
            {
              name: " Mr. A. Gandhimathinathan ",
              phone: 9986822498,
            },
          ],
          student: [
            {
              name: "Tamilselvan J",
              phone: 9080223297,
            },
            {
              name: "Shivakumar H",
              phone: 9080504205,
            },
          ],
        },
        day2: {
          date: "05 Nov 2024",
          time: "09:00am - 03:00pm",
          mode: "Individual",
           venue: "3613, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Vignesh Ramadoss",
              desig: "Technical Trainer",
            },
          ],
          staff: [
            {
              name: " Dr. Srigitha S. Nath", 
              phone: 9986822498,
            },
            {
              name: " Mr. A. Gandhimathinathan ",
              phone: 9986822498,
            },
          ],
          student: [
            {
              name: "Tamilselvan J",
              phone: 9080223297,
            },
            {
              name: "Shivakumar H",
              phone: 9080504205,
            },
          ],
        },
      },//2  

      {
        name:"Git & Github",
        logo: "EventsAssets/git.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "FULL DAY", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLScHEpj-bnrmXxTSwyjJvvYxxdub_mQY30k-6c-cf9fPgXdNdA/viewform",
        note: "All participants must bring Laptop",
        day1: {
          date: "04 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "4612, 4th Floor, SEC",
          resource: [
            {
              name: "Dr. B. Ezhilavan",
              desig: "CEO & Founder, VEI Technologies PVT.LTD",
            },
          ],
          staff: [
            {
              name: "Mrs. G. Keerthiga", 
              phone: 8825783729,
            },
            {
              name: "Mrs. K. Sakthi ",
              phone: 9962386422,
            },
          ],
          student: [
            {
              name: "Krishna S",
              phone: 7200576790,
            },
            {
              name: "Vikhram S",
              phone: 7200492450,
            },
          ],
        },
        day2: {
          date: "05 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "4612, 4th Floor, SEC",
          resource: [
            {
              name: "Dr. B. Ezhilavan",
              desig: "CEO & Founder, VEI Technologies PVT.LTD",
            },
          ],
          staff: [
            {
              name: "Mrs. G. Keerthiga", 
              phone: 8825783729,
            },
            {
              name: "Mrs. K. Sakthi ",
              phone: 9962386422,
            },
          ],
          student: [
            {
              name: "Krishna S",
              phone: 7200576790,
            },
            {
              name: "Vikhram S",
              phone: 7200492450,
            },
          ],
        },     
      },//3

      {
        name:"Getting Started with Machine Learning in Python",
        logo: "EventsAssets/gettingstart.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "FULL DAY", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSd0WDcJzL6_9EFx6BfhvLoAEq5uk0jdrby9FwZq0kTg8xkCZA/viewform",
        note: "All participants must bring Laptop",
        day1: {
          date: "06 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "3612, 3rd Floor, SEC",
          resource: [
            {
              name: "Dr. A. Devi",
              desig: "Associate Professor, IFET College of Engineering",
            },
          ],
          staff: [
            {
              name: "Ms. A. Anjaline Jayapraba", 
              phone: 9626474152,
            },
            {
              name: "Ms. T. Deepa ",
              phone: 8148451848,
            },
          ],
          student: [
            {
              name: "Salaudeen A",
              phone: 9500962067,
            },
            {
              name: "Sachin B",
              phone: 7200006675,
            },
          ],
        },
        day2: {
          date: "07 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "3612, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. S. Balasubramanian",
              desig: "Associate Professor, ECE, Meenakshi Sundararajan Engineering College",
            },
          ],
          staff: [
            {
              name: "Ms. A. Anjaline Jayapraba", 
              phone: 9626474152,
            },
            {
              name: "Ms. T. Deepa ",
              phone: 8148451848,
            },
          ],
          student: [
            {
              name: "Salaudeen A",
              phone: 9500962067,
            },
            {
              name: "Sachin B",
              phone: 7200006675,
            },
          ],
        },
       
       
      },//4

      {
        name:"Mastering in VLSI: Exploring in Cadence Tools ",
        logo: "EventsAssets/masterinVLSI.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "4332, 4th Floor, SEC",
        time: "FULL DAY", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSct-KV_41C8WYe9H161qRujHz7D0altQwZmQv5YvY83PnngEA/viewform",
        note: "All participants must bring Laptop",
        day1: {
          date: "05 Nov 2024",
          time: "09:00am - 03:45pm",
          mode: "Individual",
          venue: "4332, 4th Floor, SEC",
          resource: [
            {
              name: "Mr. Dhanagopal",
              desig: "Managing Director, Edigm Research Pvt ltd",
            },
          ],
          staff: [
            {
              name: "Dr. S. Navaneethan", 
              phone: 8939755682,
            },
            {
              name: "Dr. K. Arunkumar",
              phone: 9600981800,
            },
          ],
          student: [
            {
              name: "Tharun P R",
              phone: 7010197512 ,
            },
            {
              name: " Safia Banu M",
              phone: 9360113919,
            },
          ],
        },
        day2: {
          date: "06 Nov 2024",
          time: "09:00am - 03:45pm",
          mode: "Individual",
          venue: "4332, 4th Floor, SEC",
          resource: [
            {
              name: "Mr. Dhanagopal",
              desig: "Managing Director, Edigm Research Pvt ltd",
            },
          ],
          staff: [
            {
              name: "Dr. S. Navaneethan", 
              phone: 8939755682,
            },
            {
              name: "Dr. K. Arunkumar",
              phone: 9600981800,
            },
          ],
          student: [
            {
              name: "Tharun P R",
              phone: 7010197512 ,
            },
            {
              name: " Safia Banu M",
              phone: 9360113919,
            },
          ],
        },
       
       
      },//5

      {
        name:"Building Intelligent Robot With ROS and RoboDK : A Hands-on Workshop",
        logo: "EventsAssets/buildingintell.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "09:00am - 03:00pm", 
        link: "https://forms.gle/mLsW9ZS5EmH8WCva6",
        note: "All participants must bring Laptop",
        day1: {
          date: "07 Nov 2024",
          time: "09:00am - 03:00pm", 
          mode: "Individual",
          venue: "4612, 4th Floor, SEC",
          resource: [
            {
              name: "Mr. J. G. Karthikesh",
              desig: "CEO & Founder, Karthikesh Robotics Pvt Ltd",
            },
          ],
          staff: [
            {
              name: "Dr. R. Vinod Kumar ", 
              phone: 9865657392,
            },
          ],
          student: [
            {
              name: "Sughesh Kumar E",
              phone: 9159644663 ,
            },
            {
              name: "Naresh Kumar R S",
              phone: 8838348595,
            },
          ],
        },
        day2: {
          date: "08 Nov 2024",
          time: "09:00am - 03:00pm", 
          mode: "Individual",
          venue: "4612, 4th Floor, SEC",
          resource: [
            {
              name: "Mr. J. G. Karthikesh",
              desig: "CEO & Founder, Karthikesh Robotics Pvt Ltd",
            },
          ],
          staff: [
            {
              name: "Dr. R. Vinod Kumar ", 
              phone: 9865657392,
            },
          ],
          student: [
            {
              name: "Sughesh Kumar E",
              phone: 9159644663 ,
            },
            {
              name: "Naresh Kumar R S",
              phone: 8838348595,
            },
          ],
        },   
      },//6

      {
        name:"Sneak Peek: Web Hacking for Beginners",
        logo: "EventsAssets/sneakpeak.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "FULL DAY", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSflLu_TdkDJABj8ep9Z13rY4kauLwInEWQix72861IY5pdVTQ/viewform",
        note: "All participants must bring Laptop",
        day1: {
          date: "05 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "3731, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Santhosh",
              desig: "Cybersecurity Instructor, Cyber Heals Infotech Pvt. Ltd",
            },
          ],
          staff: [
            {
              name: "Dr. M. Vanitha", 
              phone: 7871784886,
            },
            {
              name: "Ms. A. Hema Malini", 
              phone: 9952648240,
            },
          ],
          student: [
            {
              name: "Girinath S",
              phone: 9042422785 ,
            },
            {
              name: "Nithish V",
              phone: 9360501471,
            },
          ],
        },
        day2: {
          date: "06 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "3731, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Santhosh",
              desig: "Cybersecurity Instructor, Cyber Heals Infotech Pvt. Ltd",
            },
          ],
          staff: [
            {
              name: "Dr. M. Vanitha", 
              phone: 7871784886,
            },
            {
              name: "Ms. A. Hema Malini", 
              phone: 9952648240,
            },
          ],
          student: [
            {
              name: "Girinath S",
              phone: 9042422785 ,
            },
            {
              name: "Nithish V",
              phone: 9360501471,
            },
          ],
        },
         
      },//7

      {
        name:" Integrating Security into cloud-based devops",
        logo: "EventsAssets/intergratingsec.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "FULL DAY", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSekxPzRoQJDCYbI83f7pYEwXxsaeLt02U2V6MC--jjKv1Apug/viewform?usp=send_form",
        note: "All participants must bring Laptop",
        day1: {
          date: "06 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "3613, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Moothukumar",
              desig: "Project Leader, Towards Technology",
            },
          ],
          staff: [
            {
              name: "Dr. M. Vanitha", 
              phone: 7871784886 ,
            },
            {
              name: "Ms J. Sharmila", 
              phone: 7092328836,
            },
          ],
          student: [
            {
              name: "CharanKumar R",
              phone: 8438941803 ,
            },
            {
              name: "Nithesh Kumar B",
              phone: 8610196036,
            },
          ],
        },
        day2: {
          date: "07 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "3613, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Moothukumar",
              desig: "Project Leader, Towards Technology",
            },
          ],
          staff: [
            {
              name: "Dr. M. Vanitha", 
              phone: 7871784886 ,
            },
            {
              name: "Ms J. Sharmila", 
              phone: 7092328836,
            },
          ],
          student: [
            {
              name: "CharanKumar R",
              phone: 8438941803 ,
            },
            {
              name: "Nithesh Kumar B",
              phone: 8610196036,
            },
          ],
        },
         
      },//8

      {
        name:" Build Your Own GenAI using Python ",
        logo: "EventsAssets/builtyourai.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "FULL DAY", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSfHSlrnqC_MMzcR5Uc8ELEdEFe6Nnt1tuEQK0rYpm7XK6HUuA/viewform",
        note: "All participants must bring Laptop",
        day1: {
          date: "04 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "3612, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Muhammed Ilyas",
              desig: "CEO, IT Expert Training ",
            },
          ],
          staff: [
            {
              name: "Dr. M. Vanitha", 
              phone: 7871784886 ,
            },
            {
              name: "Ms. J. Sharmila", 
              phone: 7092328836,
            },
          ],
          student: [
            {
              name: "Paradisha P",
              phone: 6385367840 ,
            },
            {
              name: "Mahalakshmi B",
              phone: 6379473406,
            },
          ],
        },
        day2: {
          date: "05 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "Individual",
          venue: "3612, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Muhammed Ilyas",
              desig: "CEO, IT Expert Training ",
            },
          ],
          staff: [
            {
              name: "Dr. M. Vanitha", 
              phone: 7871784886 ,
            },
            {
              name: "Ms. J. Sharmila", 
              phone: 7092328836,
            },
          ],
          student: [
            {
              name: "Paradisha P",
              phone: 6385367840 ,
            },
            {
              name: "Mahalakshmi B",
              phone: 6379473406,
            },
          ],
        },  
      },//9

      {
        name:"Design of Voltage Transformers",
        logo: "EventsAssets/designvoltage.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "9:00am - 3:00pm ", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeEatZ2lCi3BFKx_KIMkiwW6h5oxLUl2ixgmlWJQterBsDByw/viewform",
        note: "Soldering . Testing of wound bobbin . Winding procedure . Core placement . Stacking . Testing . Applying Varnish . Enclosure placement and vibration testing",
        day1: {
          date: "05 Nov 2024",
          time: "9:00am - 3:00pm ",
          mode: "Individual",
          venue: "4613, 4th Floor, SEC",
          resource: [
            {
              name: "Mr. K. Jeevananth",
              desig: "Managing director, Pebbels Electronics"
            },
          ],
          staff: [
            {
              name: "Dr. J. Jeffin Gracewell ", 
              phone: 8903280025 ,
            },
            {
              name: "Mr. R. Kannan", 
              phone: 9840181019,
            },
          ],
          student: [
            {
              name: "Siva R",
              phone: 8072654442 ,
            },
            {
              name: "Vishal M",
              phone: 6369904631,
            },
          ],
        },
        day2: {
          date: "06 Nov 2024",
          time: "9:00am - 3:00pm ",
          mode: "Individual",
          venue: "4613, 4th Floor, SEC",
          resource: [
            {
              name: "Mr. K. Jeevananth",
              desig: "Managing director, Pebbels Electronics"
            },
          ],
          staff: [
            {
              name: "Dr. J. Jeffin Gracewell ", 
              phone: 8903280025 ,
            },
            {
              name: "Mr. R. Kannan", 
              phone: 9840181019,
            },
          ],
          student: [
            {
              name: "Siva R",
              phone: 8072654442 ,
            },
            {
              name: "Vishal M",
              phone: 6369904631,
            },
          ],
        },
         
      },//10

      {
        name: "Building A Handwritten Digit Recognition AI Machine",
        logo: "EventsAssets/buildinghardware.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "9:00am - 3:00pm ", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSdBBvBX8_ycawF3-skOOkYR9msn5uZ7IpZkNG0GVem-2mrAdw/viewform",
        day1: {
          date: "06 Nov 2024",
          time: "9:00am - 3:00pm ",
          mode: "Individual",
          venue: "3653, 3rd Floor, SEC",
          topic:" Introduction to AI &amp; ML.Introduction to the project.Understanding the dataset.Logistic Regression Model.Logistic Regression Cost function",
          resource: [
            {
              name: "Mr. Jeswin Arul Samuel",
              desig: "Business Intelligence Engineer, Amazon Pvt Ltd"
            },
          ],
          staff: [
            {
              name: "Dr. Sowmiya Manoj ", 
              phone: 7358747803 ,
            },
            {
              name: " Mrs. V. Subashree", 
              phone: 9994365720,
            },
          ],
          student: [
            {
              name: "Bhuvaneshwaran A",
              phone: 9345604421 ,
            },
            {
              name: "Priyanka R",
              phone: 8098559549,
            },
          ],
        },
        day2: {
          date: "07 Nov 2024",
          time: "9:00am - 3:00pm ",
          mode: "Individual",
          venue: "3653, 3rd Floor, SEC",
          topic:" Gradient Descent Algorithm.Training the dataset.Evaluating the model. Creating your own handwritten digit.Predicting your handwritten digit using your model",
          resource: [
            {
              name: "Mr. Jeswin Arul Samuel",
              desig: "Business Intelligence Engineer, Amazon Pvt Ltd"
            },
          ],
          staff: [
            {
              name: "Dr. Sowmiya Manoj ", 
              phone: 7358747803 ,
            },
            {
              name: " Mrs. V. Subashree", 
              phone: 9994365720,
            },
          ],
          student: [
            {
              name: "Bhuvaneshwaran A",
              phone: 9345604421 ,
            },
            {
              name: "Priyanka R",
              phone: 8098559549,
            },
          ],
        },
       
         
      },//11
    ],
  },//ece

  {
    id: "eee",
    title: "Electrical and Electronics Engineering",
    eventslist: [
      {
        name: "Inno Block",
        date: "08 Nov 2024(FN)",
        type: "Team of 8-10",
        venue: "Will Be Updated Soon",
        time: "N/A",
        members: "N/A",
        rules: "Students will be grouped based on their academic year. Each team will be assigned a unique problem statement at the beginning of the event. The goal is to develop a solution for the given problem statement and present a detailed block diagram of the solution. The solution must be original and practical, tailored to the specific problem.Teams will have a specified time limit to complete their solution and block diagram. All work must be completed and submitted within the given time. Late submissions will not be accepted.Teams are allowed to use Google for research and information gathering. Use of any AI-based tools (other than Google) is strictly prohibited. Each team is permitted to use only one laptop for research and diagram creation.Teams will be closely monitored by event supervisors to ensure rule compliance.Any team caught using unauthorized AI tools, mobile phones, or additional laptops will be immediately disqualified.Teams must submit the final block diagram and a brief written explanation of their solution within the given time frame",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/innoblock.webp",
        staff: [
          {
            name: "Mr. S. Rajalingam",
            phone: 9790248476,
          },
        ],
        student: [
          {
            name: "Sowmia R",
            phone: 8667073236,
          },
          {
            name: "Yazhini S",
            phone: 9487116150,
          },
          {
            name: "Dhruv D Mehta",
            phone: 9025944370,
          },
          {
            name: "Visvanth P S",
            phone: 9840928549,
          },
        ],
      },//1

      {
        name: "Tech Sprint",
        date: "09 Nov 2024(AN)",
        type: "Team of 3",
        venue: "Will Be Updated Soon",
        time: "01:00pm - 03:00pm",
        note:"Correct Answer on First Attempt - Points: 10 points.Condition: If the team guesses the correct technical word within the first few seconds (eg, within 10 seconds of displaying the words).Correct Answer on Second Attempt - Points: 7 points.Condition: If the team guesses the term after receiving a hint, or within the next 10 - 20 seconds.Correct Answer on Final Attempt - Points: 5 points.Finally, The team with Highest Points will win",
        round1title: "Pictogram",
        round1: " Objective :  Teams must identify a hidden puzzled image as early as possible to get max points. This round challenges their ability to recognize partial visuals. Timing: Allocated a time limit (eg, 10-30 seconds) for each team to answer once the images are shown.Team: 10 teams with 3 members each (30 participants) Qualification: Top 7 Teams will qualify for the next round",
        round2title: "Word Chaos",
        round2: "Jumbled words (Decrypt the Word, Word Chaos): Objective : Unscramble the jumbled word to form a valid technical term.Answering : Teams should write down the answers on the provided sheets Timing: Allocated a time limit (eg, 30–60 seconds) for each team to answer once the words are shown.Team: 7 teams with 3 members each (21 participants).Qualification: Top 5 Teams will qualify for the next round",
        round3title: "Memory Hunt",
        round3: "Objective : Display images of various components (eg, resistors) on the screen for a short duration (eg, 30 seconds to 1 minute). In each round, teams will need to recall and identify the components they saw earlier. After showing the images, teams will have a short amount of time (eg, 15-20 seconds) to list down or identify the components they remember. Answering : Teams should write down the answers on the provided sheets Timing: Allocated a time limit (eg, 30–60 seconds) for each team to answer once the images are shown. Team: 5 teams with 3 members each (15 participants).Winning: Top 3 teams will move to the next round",
        round4title: "Criss Cross",
        round4: "Objective : Teams will be given a crossword puzzle with technical terms related to various fields (eg, electronics).The puzzle contains a series of clues for which teams need to provide correct answers in the crossword grid.Answering : Teams should write down the answers on the provided sheets.Timing: Allocated a time limit (eg, 5 mins) for each team to answer once the puzzles are shown.Team: 3 teams with 3 members each (9 participants).Winning: Team with Max points will be the winner",
        
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/techsprint.webp",
        staff: [
          {
            name: "Dr. R. Vinifa",
            phone: 9442912448,
          },
        ],
        student: [
          {
            name: " Dineshdharan K",
            phone: 8838593373,
          },
          {
            name: "Altrin Ahas A",
            phone: 9342641309,
          },
          {
            name: "Nidhish B",
            phone: 9345593845,
          },
          {
            name: "Santhosh D M",
            phone: 7200149348,
          },
        ],
      },//2

      {
        name: "Circuit Wizardry ",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-4",
        venue: "Will Be Updated Soon",
        time: "N/A",
        members: "Team of 2-4",
        rules: "Team composition can consist of 2 to 4 members. The time limits are as follows: for the design phase, teams have 60 to 90 minutes, and for the debug phase, they have 30 to 45 minutes. The event is open to undergraduate students pursuing electrical, electronics, or related engineering courses.  Materials provided include simulation software licenses or hardware components for circuit design. The design must be original and created during the event; pre-designed circuits or plagiarized work will lead to disqualification. Teams must submit their circuit design and debugging solution within the given time frame. Teams can choose either simulation software or hardware (as per the event's setup) to design the circuit",
        note:"Violation of time limits occurs if teams exceed the time limit by more than 10 minutes in any phase without a valid reason. Unauthorized assistance includes seeking help from external sources during the event.Design accuracy is crucial; the circuit must meet all the given design requirements. Creativity will be rewarded, as innovative use of components or creative design approaches can earn bonus points. Efficiency is important; teams must fix faults in the debug phase within the allotted time, with quick and accurate fixes scoring higher. Presentation and teams should be able to explain their design and debug process clearly to the judges",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/circuitwizardy.webp",
        staff: [
          {
            name: "John D Britto",
            phone: 9025670734,
          },
        ],
        student: [
          {
            name: " Naveen P",
            phone: 8248549321,
          },
          {
            name: "Anuranjana S Y",
            phone: 8778000121,
          },
          {
            name: " Shaik Ibrahim",
            phone: 9848763198,
          },
          {
            name: "Maniha Fathima M ",
            phone: 8925840921,
          },
        ],
      },//3

      {
        name: " Bot Race",
        date: "08 Nov 2024(AN)",
        type: "Team of 3",
        venue: "Will Be Updated Soon",
        time: "01:00pm - 03:00pm",
        rules: "Each team consists of 1-3 participants. Teams must register before the event starts, and participants should be present at the venue 20 minutes prior to the event. The event consists of a single race. The decision of the judges is final and binding.The race will be held on a predetermined track, and each team will have 2-3 minutes to complete it. The fastest completion time wins the race. In case of a tie, the team with the fewest errors will be declared the winner",
        note:"Bot Specifications:Bots must be remotely controlled or wired. The maximum size for the bots is 30 cm x 30 cm x 30 cm, and they should not exceed a weight of 5 kg. Additionally, bots must not cause damage to the track or other bots",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/botrace.webp",
        staff: [
          {
            name: "Dr. R. Karpaga Priya ",
            phone: 9597727988,
          },
        ],
        student: [
          {
            name: "Deepak R",
            phone: 9361387086,
          },
          {
            name: "Stephen U",
            phone: 6379635228,
          },
          {
            name: "Mohamed Gows Z",
            phone: 9025361714,
          },
          {
            name: "Karthikeyan S",
            phone: 7418272018,
          },
        ],
      },//4


    ],
    workshopslist: [
      {
        name: "Soar to New Heights: Master Drone Technology",
        logo: "/EventsAssets/soar.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeRuL6PfSaod7e9JAEww9q4IkllDBB1Cy4RQ4glymBji6nX9Q/viewform?usp=sf_link",
        day1: {
          date: "07 Nov 2024",
          time: "09:00am - 03:00pm",
          mode:"OFFLINE",
          venue:"will be updated soon",
          // company: "Think Big Unlocking Innovation",
        
          resource: [
            {
              name: "Mr. Jagadeeswaran",
              desig: "CEO, THINK BIG",
            },
          ],
          staff: [
            {
              name: "Dr. F. Max Savio",
              phone: 9840188187,
            },
            {
              name: "Mr. T. D. Suresh",
              phone: 9941721288,
            },
          ],
          student: [
            {
              name: "Nikkesh V",
              phone: 7010473758,
            },
          ],
        },
      },//1

      {
        name: "Exploring IOT - From Concept to Implementation",
        logo: "/EventsAssets/eee2.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSdEts6KZpSLblM-APor7GZld28eY8by32BF0gSNRjgfwp0cCQ/viewform?usp=sf_link",
        note:"Laptop Required",
        day1: {
          date: " 07 Nov 2024",
          time: "Full Day",
          mode:"OFFLINE",
          venue:"will be updated soon",
          // company: "Think Big Unlocking Innovation",
        
          resource: [
            {
              name: "K. Ilanchezhian",
              desig: "Manager – Technical, Shree Technologies A Franchisee of Prolific Systems & Technologies Pvt. Ltd., Coimbatore",
            },
          ],
          staff: [
            {
              name: "Dr. K. Kanchana",
              phone: 9444189111,
            },
            {
              name: "Dr. Monica P Suresh",
              phone: 9940345793,
            },
          ],
          student: [
            {
              name: "Presilla Mary",
              phone: 7200262279,
            },
          ],
        },
      },//2

    ],
  },//eee

  {
    id: "eie",
    title :"Electronics and Instrumentation Engineering",
    eventslist :[
      {
        name: "Tech Battle",
        date: "08 Nov 2024(FN)",
        type: "Team of 3",
        venue: "2611A (Industrial Instrumentation Laboratory), 2nd Floor, SEC",
        time: "10:00 AM - 12:00 PM",
        round1title: "Spotlight & Recall",
        round1:
          "Collect the Group of Components displayed on the Screen. Convey the image through action and find its technical term",
       
        round2title: "Guess the Expression!!",
        round2:
          "Theoretical laws will be given to the one of the participant, the expressions for those laws needed to be said by their the respective team members",
       
          round3title: "Challenge Yourself !!",
          round3:
            "11 Blocks will displayed on the screen, 3 blocks contains Bomb in them , 2 Exchange. Remaining 6 blocks contains the Specific Points .General Topics related to Electronics will be given Onspot",
       
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/techbattle.webp",
        staff: [
          {
            name: " Dr. P. Yuvaraj ",
            phone: 9865324440,
          },
        ],
        student: [
          {
            name: "Sugantha Kumar M",
            phone: 9176007792,  
          },
          {
            name: "Darshana K",
            phone: 8248477077,
          },
        ],
      },//1
      {
        name: "Digital Mania",
        date: "08 Nov 2024(FN)",
        type: "Team of 2",
        venue: "2611B (Transducer and Measurement Laboratory), 2nd Floor, SEC",
        time: "10:00am - 12:00pm",
        round1title: "ELECTROMYSTERY",
        round1:
          "Duration: 10 minutes. most words collected by a team will be qualified. Top 8 teams will be qualified for the next round",
       
        round2title: "ERROR EXPLORER",
        round2:
          "Duration: 20 minutes and negative mark of 1/2 will be there. Top 4 teams will be qualified for next round",
       
          round3title: "ELECTRONICS ARENA",
          round3:
            "Duration: 15 minutes. Winners & Runners will be selected based on points",
       
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/digitalmania.webp",
        staff: [
          {
            name: " Mr. Prabakaran",
            phone: 9047472526,
          },
        ],
        student: [
          {
            name: "Iswarya Lakshmi S",
            phone: 6382822930,  
          },
          {
            name: "Dhanush Kumar U",
            phone: 6383481684,
          },
        ],
      },//2
      {
        name: " Circuitrix ",
        tagline: "",
        date: " 09 Nov 2024(AN)",
        type: "Team of 4-5",
        venue: "2611C, 2nd Floor, SEC",
        time: "01:00pm -03:00pm",
        round1title: "Tech Clue Hunt",
        round1:
          "One member from each team will come forward to pick a random sheet from a set provided by the organizers. The sheet will contain the name of a component or object. The member who picked the sheet will then give clues to their teammates to help them guess the name. These clues can describe the usage, structure, or other characteristics of the component or object but must not include its exact name. The team earns points based on how quickly and accurately they guess the correct answer. The co-ordinator will assign a score (maximum of 5 points) based on the team’s performance within the time limit",
       
        round2title: "Replica Relay",
        round2:
          "In this round, two members from each team will be given 25 seconds to observe a pre-designed circuit. After the observation period, they will have 10 minutes to replicate the circuit as accurately as possible. The circuits and required components will be provided by the coordinators. Teams will be evaluated based on both the accuracy of the circuit connections and the time taken to complete the task. The maximum score for this round is 25 points. However, any misconduct or malpractice during the round will result in immediate disqualification",
       
          round3title: "Quiz 'N' Cross",
          round3:
            "In this final round, two teams will compete in a quiz-based X-O game, where the chairs are arranged like a tic- tac-toe board. Both teams will be asked a question at the same time, but only one member from each team  can answer. If a team answers correctly, the answering member will sit in a chair on the board. However, if the answer is incorrect, the opposing team can claim that chair as a penalty.The goal is to form a straight line with seated team members, either horizontally, vertically, or diagonally. If a team has three seated members but hasn’t formed a line, a fourth member who answers correctly can replace one of their seated teammates to help form the line. The game continues until a straight line is formed or until the 15-minute time limit is reached. If no line is formed, the team with the most correct answers in this round will win",
        
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/circuitrix.webp",
        staff: [
          {
            name: "Dr. V. Anusha Rani",
            phone: 7397496575,
          },
          
        ],
        student: [
          {
            name: "Jeginthan K",
            phone: 6374470752,
          },
          {
            name: "Bhavayoshini V ",
            phone: 9095130316,
          },
        ],
      },//3

      ],
    workshopslist:[
      {
        name: "Generative AI Essentials: Deep Dive into Models, NLP, and Image Generation",
        logo: "/EventsAssets/eie24.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company: "Karthikesh Robotics Pvt LTD",
        note:"Laptop mandatory. This workshop provides an in-depth introduction to generative AI, covering essential AI concepts, practical applications, and hands-on activities. Participants will learn about various generative models, including transformers, RNNs, and diffusion models, and will gain practical experience in natural language processing (NLP) and image generation",
        link: "https://forms.gle/7GrJx2jvrCQ2KSbs9",
        day1: {
          date: " 06 Nov 2024",
          time: "8:00 AM – 3:00 PM",
          mode: "OFFLINE",
          venue:"2611B (Transducer and Measurement Laboratory), 2nd Floor, SEC",
          topic: "Introduction to Generative AI. Basics of Generative Models. Recurrent Neural Network. NLP in Generative AI. Introduction to Image Generation Concepts. Transfer Learning. Fine-Tuning and Customization. Introduction to Retrieval Augmented Generation. Hands On experience",
          resource: [
            {
              name: "Mr. J. G. Karthikesh",
              desig: "Founder & CEO, Karthikesh Robotics Pvt Ltd",
            },
          ],
          staff: [
            {
              name: " Dr. A. R. Kalaiarasi",
              phone: 9840091709,
            },
          ],
          student: [
            {
              name: "Seethaladevi S",
              phone: 7448804523,
            },
            {
              name: "Nalina A",
              phone: 8248583372,
            },
          ],
        },
      },//1
    ],
  },//eie

  {
    id: "mba",
    title: "Management Studies",
    eventslist: [
      {
        name: " Case-In-Point Case Study Competition",
        tagline: "",
        date: " 08 Nov 2024(FN)",
        type: "Team of 2-4",
        venue: "6412, 6th Floor, SEC",
        time: "10:00am - 12:00pm",
        rules:"A team of students can consist of 2-4 members per team from the same college and from any department. Members can be from any year in college. There is no restriction on the number of teams from a college. Registered students will receive a management case study on the day of the event, with questions for analysis. Each team has to bring a laptop and prepare a PPT with solutions based on their analysis. Time will be provided to prepare the PPT. They will be given 15 mins to present their analysis. The PPT should not have more than 10 slides (including the Intro and Thank you slides). The PPT should focus on the following:. Case overview (not more than 2 mins).Identification of the problem (3 mins). Developing alternative solutions to the problem (5 mins).Reasoning for the solution identified (5 mins). The team will then be questioned on their presentation by a panel of judges. The finalists will be evaluated on the basis of their understanding of the problem, uniqueness, creativity and correctness/feasibility of the solution provided",
        
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/caseinpoint.webp",
        staff: [
          {
            name: "Dr. V. Joseph Satish",
            phone: 8790535613,
          },
          {
            name: "Dr. R. Ravimohan",
            phone: 9444016612,
          },
        ],
        student: [
          {
            name: "Badhri Narayanan M",
            phone: 9361846117,
          },
          {
            name: "Elakkiya S",
            phone: 8754709979,
          },
          {
            name: "Sridharan M",
            phone: 9629832322,
          },
          {
            name: "Reshma K",
            phone: 6374815450,
          },
        ],
      },//1

      {
        name: " Business Quiz",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "6412, 6th Floor, SEC",
        time: "09.30am - 10:00am",
        members: "Team of 2-3",
        rules: "Team Composition : Each team can consist of 2 or 3 members, and all members must be from the same college or institution. Rounds & Structure : The quiz will consist of three rounds: the Preliminary Round will feature multiple-choice questions (MCQs) on general business knowledge. The Semi-Final Round will focus on short-answer questions related to success stories of companies and famous business celebrities. The Final Round will include rapid-fire and scenario-based questions. Each round will be elimination-based, with only the top teams proceeding to the next round.  Question Types : In the Preliminary Round, there will be 25 MCQs, with 1 minute per question. The Semi-Final Round will feature 15 questions related to business success stories and business celebrities, with 1 minute per question. In the Final Round, teams will answer 5 rapid-fire scenario-based questions, with 30 seconds per question. Scoring System : Full points will be awarded for each correct answer, and there is no negative marking for incorrect answers in any round. In the event of a tie, a tie-breaker question will be asked. Use of Aids : No electronic devices (phones, calculators, etc) are allowed during the quiz. The use of paper and pens will be permitted for rough work in the Semi-Final and Final Rounds. Time Limits : Each team must answer within the specified time for each question. Failing to answer within the time frame will result in no score for that question. Conduct : Any form of cheating will result in immediate disqualification. Participants are expected to maintain decorum throughout the event. Judging & Decision Making : The quizmaster’s decision will be final in case of disputes. Teams are encouraged to respect the quiz rules and the decision of the judges",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/businessquiz.webp",
        staff: [
          {
            name: "Dr. L. Anitha ",
            phone: 9841337619,
          },

          {
            name: "Dr. V. Vimalnath ",
            phone: 9500034681,
          },
        ],
        student: [
          {
            name: " Gokul Saravanan",
            phone: 9042642652,
          },
          {
            name: "Monika D",
            phone: 7338787220,
          },
          {
            name: "Nivetha B",
            phone: 9043589924,
          },
          {
            name: "Dhanush B",
            phone: 9884553809,
          },
        ],
      },//2

      {
        name: "Best Manager",
        date: "08 Nov 2024(FN)",
        type: "Individual",
        venue: "6412, 6th Floor, SEC",
        time: "08:00am - 10:00pm",
        rules: "The number of participants for the event will be 1 or 2 per college. The Best Manager competition will consist of 3 to 4 different rounds conducted throughout the day at regular intervals. The rounds for the Best Manager will include decision-making and leadership assessment, personal branding and elevator pitch, a debate challenge, and rapid case analysis and presentation, which involves presenting a common case study with a presentation time of 5 minutes for the oral presentation. Participants are allowed to bring their laptops if needed. Additionally, there will be a crisis management simulation",
        round1title: "Decision-Making and Leadership Assessment",
        round1: "Each participant will answer 30 multiple-choice questions related to decision-making, leadership, and problem-solving",
        round2title: "Personal Branding and Elevator Pitch",
        round2: "Each participant will deliver a personal pitch, presenting themselves as a brand. They must describe their skills, strengths, achievements, and values in a way that reflects their unique value as a future manager",
        round3title: "Debate Challenge",
        round3: "Individuals are paired and given a relevant management topic. They must prepare arguments for or against the topic and engage in a structured debate",
        round4title: "Rapid Case Analysis and Presentation",
        round4: "Participants are provided with a brief, high - stakes business case. They must quickly analyze the case, identify the key issues, and develop a concise action plan. Presentation: After analyzing, participants will present their recommended solutions in a clear and structured 5-minute presentation to the judges",
        round5title: "Crisis Management Simulation",
        round5: "Each participant is placed in a hypothetical crisis (eg, a PR issue, a sudden drop in sales, or an employee strike). They must make quick decisions and manage the crisis while answering questions from the panel",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/bestmanager.webp",
        staff: [
          {
            name: "Dr. J. Elango",
            phone: 8838499674,
          },
        ],
        student: [
          {
            name: " Srinidhi S",
            phone: 9629808541,
          },
        ],
      },//3

      {
        name: "AD-ZAP",
        date: "08 Nov 2024(AN)",
        type: "Team of 5",
        venue: "6412, 6th Floor, SEC",
        time: "01:00pm - 03:00pm",
        rules: "Each team can consist of a maximum of 5 members. The product name will be given on the spot, and teams will have 20 minutes for preparation. Vulgarity or offensive terms will lead to disqualification. The time to perform the advertisement is 5 minutes, followed by a 2-minute presentation and Q&A session. Exceeding the time limit may result in penalties. Teams are encouraged to use humor, innovation, and creativity to engage the audience. Participants can bring any required materials. The judges' decision will be final",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/adzap.webp",
        staff: [
          {
            name: "Dr. Catherine",
            phone: 8148915712,
          },
          {
            name: "Dr. P. Shalini",
            phone: 9940116246,
          },
        ],
        student: [
          {
            name: " Shantanu Raj K",
            phone: 7339287524,
          },
          {
            name: " Prabhanjen V ",
            phone: 6383825014,
          },
         
        ],
      },//4

      {
        name: "IPL Auction",
        date: "09 Nov 2024(FN)",
        type: "Team of 2",
        venue: "6412, 6th Floor, SEC",
        time: "10:00am - 12:00pm",
        rules: "The competition will have 2 rounds. Participants can be from any department and any year of study. Each team consist of 2 participants. Participants who do not adhere to the rules and regulations will be disqualified at any phase of the event. Decision of the Judges and the Organizing Committee will be final. Using electronic gadgets like mobile phone and smart watches is strictly prohibited. Judgment criteria - Player rating, Playing XI strength Overall team strength, In case of tie the winner will be decided based on the remaining budget",
        round1title : "IPL Quiz",
        round1 : "This round will be a Quiz which comprises of 25 multiple choice questions. Questions in this round is based on the past IPL tournaments. This is a paper and pen round. Duration of this round: 20 minutes. Teams will be selected on the basis of marks",
        round2title : "IPL Auction (Bidding)",
        round2 : "8 teams for the first round will be selected to participate in this round. An equal budget will be allocated to each team.A team should have a minimum of 11 Players and a maximum of 15 Players. A team can have a maximum of 4 Overseas Players. Each team should have a playing XI with 4 Batsmen, 3 Bowlers, 3 All Rounders, and 1 Wicket keeper. Unsold players will not be re-auctioned in case of players shortage",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/iplogo.webp",
        staff: [
          {
            name: "Dr. K. Sathiyamurthi",
            phone: 9566580680,
          },
          {
            name: "Dr. M. Deepa",
            phone: 7042071986,
          },
          {
            name: "Dr. M. Venith Vijay",
            phone: 9840611531,
          },
        ],
        student: [
          {
            name: "Lavanesh",
            phone: 7305921189,
          },
          {
            name: "Selva",
            phone: 8124645775,
          },
          {
            name: "Lavanya",
            phone: 979043092,
          },
          {
            name: "Basith",
            phone: 9962664242,
          },
         
        ],
      },//5

    
  
    ],
    workshopslist: [
      {
        name: "The Art Of Mindful Communication Learning Through Games ",
        logo: "EventsAssets/mba24.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company: " Knowledge Xchange Community",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSe0QiWwzxm1jKaLJ7Npbvl_kcZ6gVYzm-FjiHZu1DtjiMRtrQ/viewform",
        note:"Certificate will be provided Only if you attend for two days. I'd card must. Participants must register in advance to attend the workshop. All participants are expected to arrive on time for each session.Actively engage in discussions and activities during the workshop. Treat fellow participants and the workshop facilitators with respect and courtesy. Do not share sensitive information about fellow participants or the workshop content outside the workshop. Turn off or silence mobile phones and other electronic devices during the sessions to minimize disruptions. Participants should bring any required materials or tools specified by the workshop organizers. Follow all instructions provided by the workshop facilitators. Feel free to ask questions and seek clarification when needed.Dress appropriately for the workshop; casual or business casual attire is usually suitable. Adhere to a professional and ethical code of conduct during the workshop.Do not record or broadcast the workshop sessions without permission.Complete any evaluation forms or surveys to provide feedback about the workshop",
        day1: {
          date: "04 Nov 2024",
          time: "08:00am - 03:00pm",
          mode: "OFFLINE",
          venue: "6512, 6th Floor, SEC",
          staff: [
            {
              name: "Dr. P. Sangeetha",
              phone: 9940173884,
            },
            {
              name: "Dr. P. Periasamy",
              phone: 9894994989,
            },
          ],
          student: [
            {
              name: "Vishnupriya K",
              phone: 7305397423,
            },
            {
              name: "Santhosh P",
              phone: 8870434353,
            },
            {
              name: "Ishvarya G",
              phone: 8667225253,
            },
            {
              name: "Shruthi K",
              phone: 7708115540,
            },
          ],
        },
        day2: {
          date: "05 Nov 2024",
          time: "08:00am - 03:00pm",
          mode: "OFFLINE",
          venue: "6512, 6th Floor, SEC",
          staff: [
            {
              name: "Dr. P. Sangeetha",
              phone: 9940173884,
            },
            {
              name: "Dr. P. Periasamy",
              phone: 9894994989,
            },
          ],
          student: [
            {
              name: "Vishnupriya K",
              phone: 7305397423,
            },
            {
              name: "Santhosh P",
              phone: 8870434353,
            },
            {
              name: "Ishvarya G",
              phone: 8667225253,
            },
            {
              name: "Shruthi K",
              phone: 7708115540,
            },
          ],
        },
      },//1

    ],
  },//mba

  {
    id: "mech",
    title: "Mechanical Engineering",
    eventslist: [
      {
        name: " Ideathon",
        date: "08 Nov 2024(AN)",
        type: "Team of 2",
        venue: "6612, 6th Floor, SEC",
        time: "01:00pm - 03:00pm",
        rules: "The topic will be revealed at the time of the event, and participants must analyze the problem statement. They will have one hour to create an idea for the topic and prepare a PowerPoint presentation. Based on their innovation, five teams will be selected to advance to the next round. The selected teams will present in the second round, and prizes will be awarded based on the presentation and their ideology",
        note:"Participants should arrive at the venue 15 minutes early and follow the rules set for the event. Laptops are mandatory, and each student should have their own. It's important to pay attention to instructions from the event supervisors and ensure a proper internet connection is available. Additionally, participants should bring chargers for their laptops and phones.Participants should avoid sharing their ideas with other teams and refrain from extending their time for creating ideas. Additionally, if two teams submit the same idea, both will be disqualified",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/ideathon.webp",
        staff: [
          {
            name: "Dr. R. Selvam",
            phone: 9445181113,
          },
        ],
        student: [
          {
            name: "Divesh J",
            phone: 9150935187,
          },
          {
            name: "Barath Kumar T",
            phone: 9025363627,
          },
        ],
      },//1

      {
        name: " 3D Visionaries ",
        date: "08 Nov 2024(FN)",
        type: "Individual",
        venue: "CAD Lab, SEC",
        time: "09:00am - 11:00am",
        note:"Arrive at the venue 15 minutes early. Ensure that participants have their own Fusion licenses. Study the given model carefully before starting. Pay attention to instructions from the event coordinators. Finish the model within the allocated time",
        rules: "Do not use any external aids, such as pre-designed models, and refraining from discussing or seeking help from other participants",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/3dvisionaries.webp",
        staff: [
          {
            name: "Dr. M. P. Saravanan",
            phone: 8608622418,
          },
        ],
        student: [
          {
            name: "Sarath Krishna D",
            phone: 9150730498,
          },
          {
            name: "Jeeva A",
            phone: 8148832518,
          },
        ],
      },//2

      {
        name: "Water Rocketry",
        date: "08 Nov 2024(AN)",
        type: "Team of 2",
        venue: "Bike Parking, SEC",
        time: "01:00 - 03:30pm",
        rules: "Addon weights like stones or paper bundles inside the rocket are strictly prohibited. Each team is allowed to bring only one rocket, and changing or repairing rockets for further rounds are not permitted. Teams should avoid sharing rockets with others. While participants can build their rockets on site, they will not be provided with any materials",
        note:"Here's the information for the Water Rocketry event without points:Participants should arrive at the venue 15 minutes early and follow all safety rules. It's important to pay attention to the instructions given by event supervisors. Only plastic bottles are allowed to be used for building the rocket, and each team must bring their own rocket. Participants should also bring any necessary materials in case they need to build their rocket on the spot",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/waterrocketry.webp",
        staff: [
          {
            name: "Dr. A. Thamarai selvan",
            phone: 8220147439,
          },
        ],
        student: [
          {
            name: " Shyam Sundar K A",
            phone: 9360719209,
          },
          {
            name: " Ajay Joshua M",
            phone: 8925014603,
          },
        ],
      },//3

      {
        name: " Lathe Maestro",
        date: "09 Nov 2024(AN)",
        type: "Individual",
        venue: "Near Canteen, SEC",
        time: "01:00pm - 03:00pm",
        rules: "Participants should not use any external aids, such as digital modeling tools or reference materials, and should avoid adjusting machine settings without authorization. It's important to refrain from discussing or seeking help from other participants and to inform the supervisor before leaving the workstation",
        note:"Participants must arrive at the venue 15 minutes early and study the provided 2D projection or model carefully before starting. It's important to follow all safety rules and machine operation protocols while paying attention to instructions from event supervisors",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/lathemaestro.webp",
        staff: [
          {
            name: "Mr. R. Prakash",
            phone: 9894288421,
          },
        ],
        student: [
          {
            name: "Gopi K R",
            phone: 9789006354,
          },
          {
            name: "Harish Kumar R",
            phone: 7305264191,
          },
        ],
      },//4

      {
        name: "Cruisin' Quiz",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "6412, 6th Floor, SEC",
        time: "09:00am - 11:00am",
        note:"Participants will compete in different rounds like Ignition, Torque Test, and Rev Limit, each filled with car facts, brand trivia, and industry insights. The quiz features varied formats including Multiple Choice, Rapid Fire, and Audio-Visual rounds to maintain engagement. Advancement is based on performance, as only the top teams progress, with increasing challenges to heighten the competition. The top three teams will secure the winning positions, and a bonus round will resolve any ties. This event is designed for all auto enthusiasts, from casual fans to dedicated aficionados",
        rules: "Devices: Allowed in Round 1 only. Conduct: Respectful behaviour is required; disruptive actions lead to disqualification. The Quiz Master’s decision is final. Tie-Breaker: Any tie will be resolved through a rapid-fire round",
        round1title : "Ignition",
        round1 : "Content: Car logos, silhouettes, and basic automotive knowledge. Advancement: Top 50-60% proceed to Round 2. Mode: Digital",
        round2title : "Torque Test",
        round2 : "Content: Alternate car names, parent companies, mergers. Elimination: Bottom 40-50% are eliminated",
        round3title : "Rev Limit",
        round3 : "Content: Current automotive events, advancements, and motorsports. Awards: Top three teams will be awarded",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/cruisinquiz.webp",
        staff: [
          {
            name: "Mr. S. Shenbagaraman",
            phone: 8015704764,
          },
        ],
        student: [
          {
            name: "Gokula Krishnan K",
            phone: 9360317837,
          },
          {
            name: "Merryll S Sharon",
            phone: 9489292364,
          },
        ],
      },//5

      {
        name: " NFS (Need For Speed)",
        date: "09 Nov 2024(AN)",
        type: "Individual",
        venue: "Near Canteen, SEC",
        time: "01:00pm - 03:30pm",
        // members: "Team of 3",
        rules: "Arrive at the venue 15 minutes early and follow all safety rules. Pay close attention to instructions given by the event supervisors",
        note:"The objective of this event is to engage participants in a competitive yet friendly race, promoting skills in RC car handling, engineering, and customization. It is open to RC car enthusiasts, hobbyists, engineering students, or anyone with an interest in remote-controlled vehicles. The event focuses on testing the speed of the cars. See you all there on race day!. Don't be late buddys!",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/nfs.webp",
        staff: [
          {
            name: "Dr. R. Rajashekar",
            phone: 9597647748,
          },
        ],
        student: [
          {
            name: "Pavithran R",
            phone: 9994838898,
          },
          {
            name: "Hemanth S",
            phone: 9042338902,
          },
        ],
      },//6
    ],
    workshopslist: [
      {
        name: "Hydrogen: Fueling the Future",
        logo: "/EventsAssets/hydrogen.webp",
        tag: "WORKSHOP",
        venue:"will be updated soon",
        color: "0, 70, 90",
        // company: "THINK BIG",
        link: "https://forms.gle/LM4JUCc2xTV3YuEc9",
        day1: {
          date: "06 Nov 2024",
          mode: "offline",
          time: "10:00 AM - 3:00 PM",
          resource: [
            {
              name: "Dr. D. Sangeetha",
              desig: "Director, Centre for Composite Materials, Anna University",
            },
          ],
          staff: [
            {
              name: "Dr. G. Manimaran",
              phone: 9092265908,
            },
          ],
          student: [
            {
              name: "Madhumeena K",
              phone: 8925263119,
            },
            {
              name: " Sridharshini K",
              phone: 9342683811,
            },
          ],
        },
      },//1

      {
        name: "Autodesk Fusion",
        logo: "/EventsAssets/autodesk.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company: "THINK BIG",
        venue:"will be updated soon",
        link: "https://forms.gle/yu1n28mdhn4UasD6A",
        note:"It's Mandatory For All should have their Fusion ID from AUTODESK",
        day1: {
          date: "04 Nov 2024",
          mode: "offline",
          time: "10:00 AM - 3:00 PM",
          resource: [
            {
               name: "Mr. J. Yasar Arafath",
              desig: "Application Engineer, USAM Technology Solutions, Chennai",
            },
          ],
          staff: [
            {
              name: "Dr. V. MUTHUKUMAR",
              phone: 9176941555,
            },
          ],
          student: [
            {
              name: "Sathish R",
              phone: 9940429539,
            },
            {
              name: "Kavyaambiga K",
              phone: 9790151293,
            },
          ],
        },
        day2: {
          date: "05 Nov 2024",
          mode: "offline",
          time: "10:00 AM - 3:00 PM",
          resource: [
            {
               name: "Mr. J. Yasar Arafath",
              desig: "Application Engineer, USAM Technology Solutions, Chennai",
            },
          ],
          staff: [
            {
              name: "Dr. V. MUTHUKUMAR",
              phone: 9176941555,
            },
          ],
          student: [
            {
              name: "Sathish R",
              phone: 9940429539,
            },
            {
              name: "Kavyaambiga K",
              phone: 9790151293,
            },
          ],
        },
      },//2

      {
        name: "Aerospace Design and Testing",
        logo: "/EventsAssets/aerospace.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company: "THINK BIG",
        venue:"will be updated soon",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeRVXBRs0faEBdj4gv116ZCSDP2r0O5YwMPXH8l0JZlBPGNNg/viewform",
        day1: {
          date: "07 Nov 2024",
          mode: "Individual",
          time: "10:00 AM - 3:00 PM",
          resource: [
            {
               name: "Gladwin Immanuel R",
              desig: "Senior Design Engineer, TANCAM",
            },
          ],
          staff: [
            {
              name: "Dr. G. Gopala Rama Subramaniyan",
              phone: 9444167442,
            },
          ],
          student: [
            {
              name: " Mothy Krishnan R D",
              phone: 8072000453,
            },
            {
              name: "Mohammed Abbas A",
              phone: 9677183397,
            },
          ],
        },
      },//3
    ],
  },//mech

  {
    id: "med",
    title: "Medical Electronics",
    eventslist: [
      {
        name: "Potter Tricks",
        tagline: "",
        date: " 08 Nov 2024(AN)",
        type: "Team of 3-4",
        venue: "4653, 4th Floor, SEC",
        time: "01:00pm - 03:00pm",
        rules:"Participants to be in a team of 3-4 members .Pre requisites to join this event - Basic knowledge on electrical components, general medical equipments, computer hardware's, Basics in python .Event consists of 2 Rounds (Prelims and finals) and each round will have time limits, within which teams have to play ",
        round1title: "Wingardium Leviosa",
        round1: "Two members from each team must come forward to play .Flash cards will be provided to the participants in order to answer within given time limit.Teams with highest scores will be sent to the finals",
        round2title: "The Mind Flayer",
        round2: "Teams scrutinized from Round 1 are eligible to Participate.All team members can participate.This is a Buzzer Round, where only limited chances are given and open to all teams for answering.Teams with highest score will be given top places",
        note:"Round 2 consists of questions related to Stranger things series, make sure any of your team mate is a 'GURU' about the series, if not then no worries u can still participate",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/pottertricks.webp",
        staff: [
          {
            name: "Dr. R. Sindhuja",
            phone: 8925011559,
          },
        ],
        student: [
          {
            name: "Lokeshwari D",
            phone: 6379442663,
          },
          {
            name: "Varshini R",
            phone: 9626041971,
          },
          {
            name: "Manjushree R",
            phone: 8778633695,
          },
          {
            name: "Sanjeey Karthick B",
            phone: 6374060940,
          },
        ],
      },//1

      {
        name: "The Squid Game ",
        tagline: "",
        date: " 09 Nov 2024(FN & AN)",
        type: "Team of 4-5",
        venue: "3872 (DTE Lab), 3rd Floor, SEC",
        time: "09:00am - 03:00pm",
        rules:"The players are to stand at one end of the room and the captain at the other end.The captain stands with their back to the players, and the players move toward the captain and try to touch them while the captain is turned away.  On the count of 3, the captain turns to face the players.   When the captain turns, players should freeze in place and hold their pose.  Any player who moves, laughs, or wobbles will be asked a question.   If the player answers correctly, their team will earn points.   The team with the highest points will be selected for the next level.   The other teams will be eliminated.Each team will be given with a picture.  The picture will be consisting of a few objects in it.  One among the team members should try to memorise maximum objects in the picture.  The team that memorises maximum pictures will be given points .  Like wise each member of all the teams will be given a chance to memorise.  The team that gains maximum points will be the winning team ",
        round1title: "London London statue !",
        round1: "A game where a team of 4 or 5 students are selected ,1 of which is a captain and the others are player",
        round2title: "Memory Master",
        round2: "A game where the students will be divided in team and will be given pictures to memorise",
        note:"No change of question will be provided for the players.Once the captain chooses the particular player to answer, only that player is supposed to answer.   If the other players or captain try to help, then the entire team will be eliminated.   Each player will be given 30 seconds to answer, so they are asked to respond within their given time.The picture will be displayed only for 20secs, with in that the students should memorize . The team members are not allowed to help the one thats answering . If any team member is helping, the particular team will not be given points ",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/thesquidgame.webp",
        staff: [
          {
            name: "Mrs. Mary Adline Priya",
            phone: 9791244990,
          },
        ],
        student: [
          {
            name: "Afrah A",
            phone: 9677247430,
          },
          {
            name: "Ashwini K",
            phone: 9786747012,
          },
          {
            name: "Medhamythri R S",
            phone: 7200360906,
          },
          {
            name: "Ashok E",
            phone: 6380864998,
          },
        ],
      },//2

      {
        name: " Bio Nexus",
        date: "08 Nov 2024(FN)",
        type: "Team of 3-4",
        venue: "4652, 4th Floor, SEC",
        time: "09:00am - 12:00pm",
        round1title: "Puzzle Reveal Challenge",
        round1: "Objective: identify an image from blinking segments.Team Setup: 3 members per team, with 3 teams per batch.Activity: Teams observe image segments displayed one at a time and press the buzzer",
        round2title: "Escape Room Adventure",
        round2: "Objective: Solve medical electronics-themed puzzles in an online escape room,web page will be shared during the event.Team Setup: 3 members per team.Activity: Teams work together to solve puzzles within the virtual escape room",
        round3title: "Circuit Replication Challenge",
        round3: "Objective: Replicate a circuit in Tinkercad after a 2-minute observation.Team Setup: 3 members per team.Activity: Teams replicate a pre-built circuit based on memory.If they want to see the cicuit diagram again ,one member from the team should eat a piece of bitter gourd and see the circuit for 30 seconds",
        note:"BIO-NEXUS consists of three stages designed to test skills in observation, problem-solving, and technical accuracy",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/bionexus.webp",
        staff: [
          {
            name: "Ms. T. Indumathy",
            phone:  9003025299,
          },
        ],
        student: [
          {
            name: "Cheenu D",
            phone: 7708350437,
          },
          {
            name: "Jayarubini K",
            phone: 6381954861,
          },
          {
            name: "Gowtham Murugappan A R",
            phone: 9443094130,
          },
      
        ],
      },//3

    ],
    workshopslist: [
      {
        name: "From Imagination to Reality : Customized Crafeing of Digit Support Prostheses",
        logo: "/EventsAssets/med24.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company: "KVS BIOMEDICAL",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeN2NWlWHiP4FiW_fPEdNJuxbmABJH461TjL561ScTTGEwuBA/viewform",
        note:"Introduction to Prosthetic Design and Customization.3D Modeling and Printing for Prosthetic Applications.Material Selection and Biocompatibility.Anatomical Fit and Functional Analysis.Digital Workflow in Prosthetic Crafting.Testing and Troubleshooting Prostheses.Patient-Centric Design Considerations.All participate must bring laptop",
        day1: {
          date: " 06 Nov 2024 (FN & AN)",
          time: "09:00am - 03:00pm",
          mode: "OFFLINE",
          venue: "will be updated soon",
          list: true,
            resource: [
            {
              name: "Mr. M. Hari Vishal",
              desig: "Founder and CEO, Visil Technologies"
            },
          ],
          staff: [
            {
              name: "Ms. N. Vigneshwari",
              phone: 8056466552 ,
            },
          ],
          student: [
            {
              name: "Hari Prakash ",
              phone: 9600301926,
            },
            {
              name: "Deepika ",
              phone: 7200354009 ,
            },
          ],
        },
        day2: {
          date: " 07 Nov 2024 (FN & AN)",
          time: "09:00am - 03:00pm",
          mode: "OFFLINE",
          venue: "will be updated soon",
          list: true,
            resource: [
            {
              name: "Mr. M. Hari Vishal",
              desig: "Founder and CEO, Visil Technologies"
            },
          ],
          staff: [
            {
              name: "Ms. N. Vigneshwari",
              phone: 8056466552 ,
            },
          ],
          student: [
            {
              name: "Hari Prakash ",
              phone: 9600301926,
            },
            {
              name: "Deepika ",
              phone: 7200354009 ,
            },
          ],
        },
      },//1
    ],
  },//med

  {
    id: "scoft",
    title: "SCOFT [ AIDS | AIML | CSE | CSE (CYB) | CSE (IOT) | IT ]",
    eventslist: [
      {
        name: "Mystery Layers",
        tagline: "",
        date: "08 Nov 2024(AN)",
        type: "Team of 3",
        venue: "1481, 1st Floor, SEC",
        time: "01:00pm - 03.00pm",
        round1title: "WITNESS'S TALE:",
        round1: "Teams find the crime scene by analyzing misleading clues. Winners receive a pre-coded image classifier, while other teams must code it themselves",
        round2title: "HIDDEN DAGGERS:",
        round2: "Teams identify the murder weapon using image classification. Previous winners use a pre-coded model, while others must build their own as a sabotage challenge",
        // round3title: "",
        // round3: "Once the connections are given in the tinkercad (a website to give the connections to all the components and execute the project) code will be provided after completing the basic level IoT related quiz.Already executed programs should not be shown",
        note: "Team of 3. Laptop per team is must. Any python notebook of choice like Jupyter or Colab",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/murder-mys-24.webp",
        staff: [
          {
            name: "Mrs. S. H. Archana",
            phone: 9094375369,
          },
        ],
        student: [
          {
            name: "Sri Varshan P",
            phone: 8754453088,
          },
          {
            name: "Kanishkar M",
            phone: 9042424694,
          },
          {
            name: "Beatrice Thomas",
            phone: 9840839234,
          },
          {
            name: "Naveen Kanthan L",
            phone: 9150629605,
          },
        ],
      },//1

      {
        name: "Crypt N Hunt",
        date: "09 Nov 2024(FN)",
        type: "Team of 3",
        venue: "Lines Hall (Discussion Room), 3rd Floor, SEC",
        time: "09:00am - 12:00pm",
        rules: "Participants must work in teams of 3. Laptops and internet access are required",
        round1title: "The Tech Quiz Challenge",
        round1:"Teams will face a series of challenging quiz-based questions that test their knowledge across various technical domains. It's all about accuracy and teamwork as you work together to solve the problems and find the correct answers",
        round2title: "Navigate the Clues",
        round2: "Teams must use their technical skills to solve clues and find the next location. Each successful destination will provide a new clue, leading to the next location. Teams are allowed to use only one phone for navigation and solving the clues",
        note:"Laptops required. Internet Access needed",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/cryptNhunt.webp",
        staff: [
          {
            name: "Mrs. N Madhumitha",
            phone: 7708777640,
          },
        ],
        student: [
          {
            name: "Surendhar K",
            phone: 8220288187,  
          },
          {
            name: "Dhareene R K",
            phone: 9499948952,
          },
          {
            name: "Karthikeyan R",
            phone: 9841528046,
          },
          {
            name: "Anto Jessi A",
            phone: 8248154643,
          },
        ],
      },//2

      {
        name: "Data Note",
        date: "08 Nov 2024(AN)",
        type: "Team of 3",
        venue: "1682, 1st Floor, SEC",
        time: "01:00pm - 03:00pm",
        rules: "Participants must work in teams of 3. AI tools are prohibited",
        round1title: "L's Data Investigations",
        round1: "The setting is L's dimly lit office, cluttered with stacks of data reports and illuminated by the flickering glow of computer screens. This is where L, the world’s greatest detective, meticulously examines every detail, searching for patterns that might lead him to Kira. Participants step into this realm, embodying L's investigative spirit. Armed with a dataset filled with clues—crime statistics, social media activity, and more—they must analyze the information to uncover trends that could reveal Kira's next move. With five questions to answer, teams work frantically, applying statistical methods to determine which day had the highest crime rates or uncovering the most prevalent social media interactions. The clock ticks down, and they race to submit their findings, knowing that accuracy and clarity will earn them crucial points",
        round2title: "Kira's Preprocessing Trial",
        round2: "As the investigation deepens, the scene shifts to Kira’s hideout—an enigmatic space where strategy and manipulation reign supreme. Here, Kira devises intricate plans, and participants must adopt his cunning mindset to prevail.In Kira’s Preprocessing Trial, teams face a series of data challenges that test their collaborative skills and analytical prowess. Each task reveals a clue that will bring them closer to escaping the room, just as Kira carefully orchestrates his moves to outwit L. Teams tackle the first challenge of handling missing values, filling in gaps to ensure data integrity. Next, they confront duplicates, stripping away redundancy to present a unique dataset. Outlier detection becomes a race against time as they identify anomalies, while data type conversion ensures every piece of information is in its rightful format. The pressure mounts, and only those who solve each task with speed and accuracy will advance",
        round3title: "Shinigami's Data Vision",
        round3: "In the final act, participants enter the mystical realm of the Shinigami, where clarity and insight illuminate the darkness. Here, the true power of visualization comes to life. Just as the Shinigami can perceive lifespans, teams must create visual representations that reveal the hidden narratives within their data. With a 3x3 Bingo card in hand, participants engage in a flurry of creativity. They tackle a variety of visualization challenges—from pie charts to scatter plots—working together to communicate trends and insights that could change the game. As they complete three tasks in a row and call “Bingo!”, the anticipation builds. They present their visualizations to the judges, explaining how each chart uncovers a deeper understanding of the data. In this moment, the power of insight becomes their ultimate weapon",
        note:"Laptop with internet connection. Jupyter Notebook required / Kaggle required",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/datanote.webp",
        staff: [
          {
            name: "Mrs. K. Vishnupriya",
            phone: 9566500547,
          },
        ],
        student: [
          {
            name: "Priyanka A",
            phone: 9360202951,
          },
          {
            name: "Jenisha J",
            phone: 8610503252,
          },
          {
            name: "Malar Mariam S ",
            phone: 9445145344,
          },
          {
            name: "  Ramya R",
            phone: 8144039568,
          },
        ],
      },//3

      {
        name: "Techtopia",
        // tagline: "",
        date: "08 Nov 2024(FN)",
        type: "Team of 3-4",
        venue: "1512, 1st Floor, SEC",
        time: "09:00am - 12:00pm",
        rules:"Each team must consist of 3-4 members to participate. Solo Entries are allowed. Participants must bring their own laptops with personal data connections. Participants must code and solve challenges without using AI tools or assistants like ChatGPT or similar. Stick to the time limits—no extensions!. Cheating or copying leads to disqualification",
        round1title: "Code Rewind: Reverse the Logic!",
        round1: "Given only the final output, reconstruct the code in any language helpful that produces the given output",
        round2title: "Code Gaps: Fill the Blanks!",
        round2: "Complete an incomplete code snippet by filling in the missing parts. Time is of the essence!",
        round3title: "Tech-Trek: The Game Board Showdown!",
        round3: "Roll the dice and solve tech challenges on a digital game board. Strategize to move forward and avoid setbacks!",
        note:"One laptop per team with chargers. Familiar with programming language Python",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/techt.webp",
        staff: [
          {
            name: "Dr. Renugadevi R",
            phone: 9940381319,
          },
        ],
        student: [
          {
            name: "Alagu Nachiyar K",
            phone: 6385185289,
          },
          {
            name: "Vaishali Balamurgan",
            phone: 9786744995,
          },
          {
            name: "Daksha Subbaian",
            phone: 9840560001,
          },
          {
            name: "Aaliya Fathima",
            phone: 7695010234,
          },
        ],
      },//4

      {
        name: "Design Dhamaka",
        // tagline: "",
        date: "08 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "1511, 1st Floor, SEC",
        time: "09:00am - 12:00pm",
        rules:"Teams of 2-3 members. AI tools are prohibited. Participants must bring their own laptops with personal data connections. All the team members should know about the components and designing software",
        round1title: "Web Design",
        round1: "Participants will design a UI/UX landing page using Figma, based on a given prompt",
        round2title: "Mobile App Design",
        round2: "In this round, you will design the layout for the mobile screen with the given prompt that addresses the real-world challenges",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/design-dhamaka.webp",
        staff: [
          {
            name: "Dr. J. Aswini",
            phone: 9380977888,
          },
        ],
        student: [
          {
            name: "Nathin R ",
            phone: 9600292350,
          },
          {
            name: "Arshatha P",
            phone: 9345316992,
          },         
        ],
      },//5

      {
        name: "IOT Hacktivate",
        // tagline: "",
        date: "08 Nov 2024(AN)",
        type: "Team 1-3",
        venue: "2581, 2nd Floor, SEC",
        time: "1:00pm to 3:00pm",
        members: "Team of 1-3",
        rules:"Teams of 1 - 3 members. Can use proteus or Tinckercad  Participants must bring their own laptops with personal data connections",
        round1title: "The Sketch Showdown (Figma Design Sprint)",
        round1: "contestants will decode thehidden links between images and dive deep into the world of IoT!",
        round2title: "IoT Circuit Quest",
        round2: "contestants will be handed incomplete IoT circuits or system designs. Your task is to identify the missing components and sensors",
        round3title: "Built The Future",
        round3: "contestants will be presented with the real world iot problem and they have to Develop from scratch, Design the circuit, Code the logic and bring the system to life",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/iothackative.webp",
        staff: [
          {
            name: "Dr. C. H. Vasanth Kumar",
            phone: 8122328543,
          },
        ],
        student: [
          {
            name: "Yuvarani T",
            phone: 6383257380,
          },
          {
            name: "Keerthana Jayasri S K",
            phone: 9342565093,
          },         
        ],
      },//6

      {
        name: "Web Wizards",
        // tagline: "",
        date: "09 Nov 2024(FN & AN)",
        type: "Team of 1-4",
        venue: "1561 (XR Lab), 1st Floor, SEC",
        time: "09:00am - 03:00pm",
        round1title: "Presentation of working prototype of their pre-made website",
        round1: "Participants will present a working prototype of their pre-made website. Teams can consist of 1 to 4 members. Participants are allowed to use any tools and technologies, including AI, but must demonstrate significant personal contribution and understanding of the code. They will explain their unique touch and showcase their technical skills during the presentation. Evaluation criteria include originality, technical execution, and user experience",
        round2title: "Live Development Challenge",
        round2: "Finalists will participate in a live session to build a fully functional website within a set time limit. Participants can use any resources but must display their coding skills and creativity while explaining how their code works. Judges will assess the personal contribution and technical depth of the project. Finalists must be prepared to answer questions about their development process and the choices they made during the build",
        note:"Laptops are mandatory. A reliable internet connection is required from participants for seamless participation. Web development knowledge, including HTML, CSS, and JavaScript, is necessary from participants. Creativity and problem-solving skills are expected from participants to tackle coding challenges effectively",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/webwizard.webp",
        staff: [
          {
            name: "Dr. V. Uma Rani",
            phone: 8610352527,
          },
        ],
        student: [
          {
            name: "Roobesh Rao E D",
            phone: 7904379389,
          },
          {
            name: " Bakkiya Lakshmi M",
            phone: 9003434339,
          },
          {
            name: "Jayabirami S",
            phone: 8122934948,
          },
          {
            name: "Sonu S",
            phone: 9840916273,
          },          
        ],
      },//7

      {
        name: "Code Crusade",
        // tagline: "",
        date: "08 Nov 2024(FN)",
        type: "Team of 1-3",
        venue: "1482 (IOT Lab), 1st Floor, SEC",
        time: "09:00am - 12:00pm",
        round1title: "Connections",
        round1: "Participants decode visual clues, with each set of images representing a specific programming concept or technical term. The goal is to identify the correct term based on abstract visuals, covering ideas like algorithms, data structures, and general programming terms. The quiz is conducted online with interactive elements for direct engagement. Focus areas include programming fundamentals, algorithms, logic-based riddles, and tech trivia related to data structures. Each question has a 30-second time limit to challenge quick thinking and connections. The top 50% of participants qualify for Round 2 based on their first-round scores",
        round2title: "The Code Doctor",
        round2: "Participants act as code doctors, diagnosing and fixing errors in provided code snippets. Bugs include syntax issues, logical errors, and runtime problems. The format involves debugging faulty code, with focus areas on syntax errors, logical errors, and runtime errors. Programming languages used are Python, C, or Java. Participants have a duration of 20 minutes to debug 3-5 code snippets. Advancement is determined by those who debug the highest number of issues within the time limit qualify for the final round",
        round3title: "Mastermind's Relay",
        round3: "Teams of 3 participants tackle a complex coding problem in a relay format. Each team has 3 participants, with each member coding in turns before tagging the next. Once the relay starts, participants cannot communicate; each coder must continue from the previous member’s work without direct help. The problem is to be solved using Python, C, or Java. The total round duration is 30 minutes. Teams are evaluated on accuracy, efficiency, completeness of the solution, and teamwork during the relay",
        note: "Participants should bring their own laptops or devices for each team. They must have an active HackerRank account to access and participate in the contest. Participants should be familiar with basic debugging techniques, coding principles, and algorithmic problem-solving",
        tag: "EVENT",
        color: "0, 1, 38",  
        logo: "/EventsAssets/codecrusade.webp",
        staff: [
          {
            name: "Ms. S. P Panimalar",
            phone: 9789703697,
          },
        ],
        student: [
          {
            name: "Aparna R B",
            phone: 9382338204,
          },
          {
            name: " Varshini S",
            phone: 7904580233,
          },  
          {
            name: "Gokul Sharan R",
            phone: 9791486718,
          },
          {
            name: " Karthick V",
            phone: 8190013743,
          },       
        ],
      },//8

      {
        name: "Prompt Arena",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "2512, 2nd Floor, SEC",
        time: "09:00am - 12:00pm",
        rules:"Each team must consist of 2-3 members. Teams must use the provided AI tools for image replication and comic creation. All submissions must be original and created during the event. Each round has a specified time limit. Teams will be judged based on creativity, originality, and adherence to the prompt",
        round1title: "Copycat",
        round1:"Participants will be given a pre-generated image created using AI tools, and their task is to replicate it as closely as possible. This round tests not only their creativity but also their precision and attention to detail. Can you match the AI’s creation using your artistic skills? The top 10 teams will advance to the second round",
        round2title: "Comic Quest",
        round2: "In the second round, the stakes are raised! Participants will be provided with a specific scenario, and their challenge is to generate their own comic using AI tools for image generation. They’ll need to create characters, scenes, and a compelling narrative that fits the given scenario. This round emphasizes storytelling, creativity, and the ability to seamlessly integrate AI-generated images into a cohesive comic. The AI-generated comic should be 10 pages",
        note:"Required - Laptop with Internet connection",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/prompt.webp",
        staff: [
          {
            name: " Dr. M. Ulagammai",
            phone: 9842925311,
          },
        ],
        student: [
          {
            name: "Prema Latha S",
            phone: 8124694321,  
          },
          {
            name: " Nithyaa Sri S S",
            phone: 9487319445,
          },
          {
            name: "Kamalesh S",
            phone: 9444436536,
          },
          {
            name: " Kiran Balaji H",
            phone: 9600141495,
          },
        ],
      },//9

      {
        name: "Escape Room",
        date: "08 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: "1561 (XR Lab), 1st Floor, SEC",
        time: "09:00am - 12:00pm",
        round1title: "Scriptum Invisum",
        round1: "Teams will be provided access a designated webpage . Participants must carefully analyze the webpage, looking for clues and hidden passcodes embedded in different sections",
        round2title: "Imago Analysare",
        round2: "Teams will be provided with a digital image .  Teams must uncover the passkey by analyzing the image , which will lead to the third level where they will be provided with their next challenge",
        round3title: "OSINT (Open Source Intelligence)",
        round3: "In this task teams will complete a quiz consisting of 10 questions which should be answered using OSINT tools. Teams must answer all questions  to proceed. Upon successful completion, they will receive their final task",
        round4title: "Frange Clavem",
        round4: "Teams will receive a protected file as their final challenge  . Teams may  crack the password using Kali Linux . The first team to successfully crack the password and submit it correctly will be declared the winner",
        note:"Laptops (one per team). Internet access. Tools - Kali Linux",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/escaperoom.webp",
        staff: [
              {
                name: "Dr. R. N. Karthika",
                phone: 8248816873,
              },
            ],
            student: [
              {
                name: " Varshini S A",
                phone:  8531868861,  
              },
              {
                name: " Shanmathi S",
                phone: 6382398087,
              },
              {
                name: "Infantina Maria L",
                phone: 9361680748,
              },
              {
                name: "Dharshiniyaa K S",
                phone: 9787005451,
              },
            ],
      },//10

      {
        name: "The Web Showdown",
        date: "08 Nov 2024(FN)",
        type: "Team of 1-3",
        venue: "1681, 1st Floor, SEC",
        time: "10:00am - 12:00pm",
        rules:"Teams of 1-3 members. AI tools are prohibited. Participants must bring their own laptops with personal data connections",
        round1title: "The Code Crunch (Web Dev Quiz)",
        round1: "Participants must complete a quiz based on web development within a stipulated time. Multiple-choice questions will be provided to test knowledge on HTML, CSS, JavaScript, and general web technologies",
        round2title: "The Style Showdown (Extreme Makeover: Web Edition)",
        round2: "Using the provided HTML skeleton, teams will enhance the website using CSS to create a compelling design",
        round3title: "The Final Duel (Random Element Roulette)",
        round3: "Teams will create a website based on a given idea, with design twists introduced every 5 minutes (eg, use limited colours, add a quirky GIF)",
        note: "Laptop (Mandatory) and Data Connection",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/webshadows.webp",
        staff: [
          {
            name: " Dr. V. Loganathan",
          },
        ],
        student: [
          {
            name: "Arjun M",
            phone:  8148918219,  
          },
          {
            name: "Abinav Sankar S",
            phone: 8610304570,
          },
          {
            name: "Lakshmi Priya V",
            phone: 6381478448,
          },
          {
            name: "Harini A",
            phone: 9962050598,
          },
        ],
      },//11

      { 
        name: "Algoinception",
        date: "09 Nov 2024(FN)",
        type: "Team of 2-3",
        venue: " 1482 (IOT Lab), 1st Floor, SEC",
        time: "09:00am - 12:00pm",
        rules:"Teams must consist of 2-3 participants(Minimum - 2). Laptops and internet usage are permitted, but plagiarism is strictly prohibited. Participants should solve problems sequentially in both rounds. Any violation of rules may lead to disqualification",
        round1title: "Image Puzzle Grid Challenge",
        round1: "Grid Setup: The grid is arranged in a 3x3 format, with each square hiding part of a larger image. The coding challenges inside each grid range in difficulty. Solving and Revealing: When a team solves a problem, the corresponding grid section is revealed. The goal is to reveal as many sections as possible within the time limit and identify the image. Sequential Order: Problems must be solved in a specific order, unlocking adjacent grids as teams progress. Hint System: Teams may request hints, but doing so may add penalties to their overall score",
        round2title: "Folder Challenge – The Path to Victory",
        round2: "Folder Setup: Each folder contains a coding problem, increasing in difficulty from Easy to Hard. Progression: Teams must first solve the Easy problem to move on to the Medium problem. If they fail at any stage, they must restart from the Easy folder. Final Question: Successfully solving the Hard problem unlocks the Final Question, which determines the event winner. Hints and Penalties: A limited number of hints are available, but using them may impose time penalties or other restrictions",
        note:"Laptop required (one per team). Knowledge in any programming language(C, C++, Python or Java). General knowledge of programming and problem-solving",
        tag: "EVENT",
        color: "0, 1, 38",
        logo: "/EventsAssets/AlgoInception.webp",
        staff: [
          {
            name: "Mrs. E. Silambarasi",
            phone: 9790644896,
          },
        ],
        student: [
          {
            name: " Navin Kumar J",
            phone:  9344799648,  
          },
          {
            name: "Santhosh U",
            phone: 6381551870,
          },
          {
            name: "Amirthavarshini V",
            phone: 9043910536,
          },
          {
            name: "Sabeeha Shaik",
            phone: 9063992808,
          },
        ],
      },//12
    ],

    workshopslist: [
      {
        name: "WEB CRAFT STUDIO",
        logo: "EventsAssets/web-st-24.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: "09.00 AM to 03.00 PM",
        note:"Laptop Required", 
        note: "This workshop focuses on the MERN stack, taking participants from the basics of web development through to building fully functional, full-stack applications. Participants will also learn advanced deployment techniques, equipping them with comprehensive skills in MongoDB, Express, React, and Node js to create dynamic and scalable web applications",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSckuUw8tfhqI48sVjd41H_A11htc4fPlCZ8X_n5XGE333vm6A/viewform",
        day1: {
          date: "04 Nov 2024",
          mode: "OFFLINE",
          time: "09.00 AM to 03.00 PM",
          venue: "1481, 1st Floor, SEC",
          resource: [
            {
              name: "Mr. M. Kameshwaran",
              desig: "Associate Engineer, Presidio",
            },
          ],
          staff: [
            {
              name: "Mrs. G. Abinaya",
              phone: 9500206093,
            },
          ],
          student: [
            {
              name: "Aadhithya D",
              phone: 7397381695,
            },
            {
              name: "Divya K",
              phone: 6381005748,
            },
            {
              name: "Atchaya K",
              phone: 6374499788,
            },
            {
              name: "Sandhiya Shree B",
              phone: 6380108204,
            },
          ],
        },
        day2: {
          date: " 05 Nov 2024",
          time: "09:00am - 03.00pm",
          mode: "OFFLINE",
          venue: "Lines Hall, 3rd Floor, SEC",
          resource: [
            {
              name: "Mr. Kameshwaran M",
              desig: "Associate Engineer, Presidio",
            },
          ],
          staff: [
            {
              name: "Mrs. G. Abinaya",
              phone: 9500206093,
            },
          ],
          student: [
            {
              name: "Aadhithya D",
              phone: 7397381695,
            },
            {
              name: "Divya K",
              phone: 6381005748,
            },
            {
              name: "Atchaya K",
              phone: 6374499788,
            },
            {
              name: "Sandhiya Shree B",
              phone: 6380108204,
            },
          ],
        },
      },//1

      {
        name: "JUPYTER IOT SANDBOX",
        logo: "EventsAssets/jupyter.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company: "Faculty of Saveetha Engineering College",
        note:"This workshop aims to equip participants with the knowledge and skills to harness the power of the Internet of Things (IoT) using Python. We will focus on connecting a sensor to an Node MCU microcontroller and integrating it with a Jupyter Notebook for data analysis and visualization. Laptop with Jupyter notebook installed (MANDATORY)",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSf09tx3Trmob0lafaakyW3chpIVjHK0oRKNmPGEE_wMPmTyHw/viewform",
        day1: {
          date: "07 Nov 2024",
          time: "Full Day",
          mode: "OFFLINE",
          venue: "1561 (XR Lab), 1st Floor, SEC",
          list: true,
          resource: [
            {
              name: "Dr. C. H. Vasanth Kumar",
              desig: "Assistant Professor, SCOFT, Saveetha Engineering College",
            },
          ],
          staff: [
            {
              name: "Dr. J. Aswini",
              phone: 9380977888,
            },
          ],
          student: [
            {
              name: "Kavi Keerthana R ",
              phone: 6381643001,
            },
            {
              name: "Sathish R",
              phone: 8778079453,
            },
            {
              name: "Kishore Narayanan S R ",
              phone: 9176125008,
            },
            {
              name: "Divya M ",
              phone: 9361811803,
            },
          ],
        },
      },//2   

      {
        name: "Tested academy career acceralator",
        logo: "EventsAssets/scoft2.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company:  "YETHI",
        note:"Join us for an insightful day of learning and hands-on experience designed to equip you with essential knowledge in the banking domain, software testing, automation, and Tenjin's capabilities. Our event features interactive sessions and real-time application, making it ideal for those looking to sharpen their skills and apply industry-relevant practices. We’re thrilled to announce that all event participants will receive one month of FREE access to Tenjin’s powerful platform!. This exclusive offer allows you to explore and utilize Tenjin's advanced data and automation tools, giving you the hands-on experience needed to stand out in tech and analytics roles",
        link: "https://forms.gle/68uN1gf1WpYZLbfL9",
        day1: {
          date: "05 Nov 2024",
          time: "09:00am - 03:00pm",
          mode: "OFFLINE",
          venue: "1511, 1st Floor, SEC",
          list: true,
          resource: [
            {
              name: "Mr. Sitesh Srivastava",
              desig: "Chief Customer 'Success' Officer (CCO), Yethi Consulting (yethi.in), Director - TESTED Academy"
            },

            {
              name: "Mr. R. V. Chari",
              desig: "Senior Solution Architect Post Graduate Banker and Ten years of experience in software related activities in Bank"
            },

          ],
          staff: [
            {
              name: "Ms. S. P. Panimalar",
              phone: 9789703697,
            },
          ],
          student: [
            {
              name: "Samyuktha S",
              phone: 8838907737,
            },
            {
              name: "Sri Sai Priya S",
              phone: 9499948640,
            },
            {
              name: "Aldrin S",
              phone: 7825060999,
            },
            {
              name: "Arularasi U",
              phone: 8778433040,
            },
          ],
        },
      },//3

      {
        name: "Empowering Design - A Hands-On UI/UX Workshop",
        logo: "EventsAssets/empower.jpg",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        company:  "RETECH Solutions",
        note:"Provide hands-on experience in UI/UX design using Figma. Equip participants with practical skills in wireframing, prototyping, and usability testing. Enhance participants' understanding of the importance of design, How good design impacts not only technology but also everyday life and user satisfaction. Laptops (Required) with Figma pre-installed and an internet connection for participants",
        link: "https://docs.google.com/forms/d/e/1FAIpQLScUEgS_paQp3mHKeNtN7qHOinyHtjm2yhoizVOHe9o7HN2NRA/viewform",
        day1: {
          date: " 06 Nov 2024",
          time: "10:00am - 03:00pm",
          mode: "OFFLINE",
          venue: "1512, 1st Floor, SEC",
          list: true,
          resource: [
            {
              name: "Mr. Dilli Ganesh",
              desig : "UI/UX Mentor at Retech Solutions Pvt Ltd"
            },
          ],
          staff: [
            {
              name: "Mrs. S. Dhivya Dharshini",
              phone: 8754468216,
            },
          ],
          student: [
            {
              name: " Arshatha",
              phone: 9345316992,
            },
            {
              name: " Nathin",
              phone: 9600292350,
            },
          ],
        },
      },//4

      {
        name: "3D Fabrication Hub",
        logo: "EventsAssets/scoft5.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: "9:00 AM to 3:00 PM",
        note:"The workshop offers a comprehensive introduction to 3D printing, covering essential concepts, hands-on practices, and advanced techniques. The sessions are designed to guide participants through the basics, design, preparation, and execution of 3D printing, ensuring a complete understanding of the technology",
        link: "https://forms.gle/Bx6QJCPr21q5Kc8t6",
        day1: {
          date: "06 Nov 2024",
          mode: "OFFLINE",
          time: "09.00 AM to 03.00 PM",
          venue: "1561 (XR Lab), 1st Floor, SEC",
          resource: [
            {
              name: "Dr. S. Sellakumar",
              desig: "Assistant Professor, Department of Mechanical Engineering (SCOFT)",
            },
            
          ],
          staff: [
            {
              name: "Mrs. G. Abinaya",
              phone: 9500206093,
            },
          ],
          student: [
            {
              name: "Sam Israel D ",
              phone: 8825434325,
            },
            {
              name: "Vijay Kumar B",
              phone: 9080390172,
            },
          ],
        },
      },//5

      {
        name: "Cybersecurity Foundation Essentials",
        logo: "EventsAssets/scoft3-24.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: "09.00 AM to 03.00 PM",
        note:"This workshop introduces participants to fundamental cybersecurity concepts, with a focus on hands-on demonstrations of various hacking techniques and defensive strategies. The session, led by Abhishek Kumar and Aditi Rai, will cover real-world applications of cybersecurity tools and provide practical experience in tackling everyday cyber threats",
        link: "https://forms.gle/oWZYHo3xQWVULDhk9",
  
        day1: {
          date: "07 Nov 2024",
          time: "9:00 AM to 3:00 PM",
          mode: "OFFLINE",
          venue: "1512, 1st Floor, SEC",
          topic: "Wi-Fi Cracking, Android Hacking, ⁠⁠Browser Hacking, ⁠⁠Website hacking, ⁠⁠Bug Bounty, ⁠⁠Windows Hacking, ⁠⁠Dark Web, ⁠⁠Daily Cyber Threats",
          resource: [
            {
              name: "Mr. Abhishek Kumar",
              desig: "Founder - CyberTeam",
            },
            {
              name: "Ms. Aditi Rai",
              desig: "Founder - CyberTeam",
            },
            
          ],
          staff: [
            {
              name: "Mrs. S. Dhivya Dharshini",
              phone: 8754468216,
            },
          ],
          student: [
            {
              name: "Jayamani R",
              phone: 9962244445,
            },
            {
              name: "Jayavarthan P",
              phone: 9344524024,
            },
            {
              name: "Gayathri T",
              phone: 8148800567,
            },
            {
              name: "Hanshika Varthini R",
              phone: 7812881773,
            },
          ],
        },
        
      },//6
      
      {
        name: "MERN Mobile Synergy",
        logo: "EventsAssets/mern.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        // company: "Faculty of Saveetha Engineering College",
        note:"Dive into a hands-on workshop where you’ll use MongoDB, Express, React, and Node to create a sleek registration app. Perfect for beginners--learn to register and display students with real- world skills!!. Laptop With Node JS, Visual Studio Code and MongoDB installed",
        link: "https://forms.gle/jni66VzWw8wcAPaU7",
        day1: {
          date: "05 Nov 2024",
          time: "09:00am - 03:00pm",
          mode: "OFFLINE",
          venue: "1481, 1st Floor, SEC",
          list: true,
          // topic: "MERN Mobile Synergy",
          resource: [
            {
              name: "Mr. J. V. Logesh",
              desig: "Instructor, JVL code"
            },
          ],
          staff: [
            {
              name: " Ms. C. Silambarasi",
              phone: 9790644896,
            },
          ],
          student: [
            {
              name: " Sanjushri A ",
              phone: 9384597482,
            },
            {
              name: "Roopak C S",
              phone: 9994454835,
            },
            {
              name: "Dhiraviya S",
              phone: 9363139971,
            },
            {
              name: "Durgadevi P",
              phone: 9894841468 ,
            },
          ],
        },
        day2: {
          date: "06 Nov 2024",
          time: "09:00am - 03:00pm",
          mode: "OFFLINE",
          venue: "1511, 1st Floor, SEC",
          list: true,
          // topic: "MERN Mobile Synergy",
          resource: [
            {
              name: "Mr. J. V. Logesh",
              desig: "Instructor, JVL code"
            },
          ],
          staff: [
            {
              name: "Ms. C. Silambarasi",
              phone: 9790644896,
            },
          ],
          student: [
            {
              name: "Sanjushri A ",
              phone: 9384597482,
            },
            {
              name: "Roopak C S",
              phone: 9994454835,
            },
            {
              name: "Dhiraviya S",
              phone: 9363139971,
            },
            {
              name: "Durgadevi P",
              phone: 9894841468 ,
            },
          ],
        },
      },//7

      {
        name: "Cyber Forensics Decode",
        logo: "EventsAssets/cyber.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: "Full Day",
        note:"This workshop provides a foundational understanding of cyber forensics, its key principles, and practical skills for dealing with digital evidence. The sessions are designed to cover the essentials of cyber forensics and offer hands-on experience in handling digital evidence using forensic tools",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSedJSPVDcB615ZZ4xek94qX22pWU436Ud5CWFn_58bBH3uCgg/viewform",
  
        day1: {
          date: "04 Nov 2024",
          time: "Full Day",
          mode: "OFFLINE",
          venue: "1682, 1st Floor, SEC",
          resource: [
            {
              name: "Mr. Sylesh",
              desig: "Senior Manager - DFIR, Alibi Technologies LLP",
            },
            
          ],
          staff: [
            {
              name: " Ms. S. P. Panimalar",
              phone: 9789703697,
            },
          ],
          student: [
            {
              name: " Sanjay Ragavendar M K",
              phone: 9597409702,
            },
            {
              name: " Kishore M",
              phone: 7397331440,
            },
            {
              name: " Arjun N S",
              phone: 9344103428,
            },
            {
              name: " Mohammad Saajid S",
              phone: 8870413906,
            },
          ],
        },
        
      },//8

      {
        name: "UiPath Robotic Automation Mastery",
        logo: "EventsAssets/robotics.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "will be updated soon",
        time: "Full Day",
        note:" Laptop mandatory. Dive into the world of Robotic Process Automation (RPA) with this hands-on, two-day UiPath workshop designed for beginners and professionals alike. On Day 1, you'll master RPA basics: setup, exploring the UiPath environment, and building your first automated workflows, including web scraping and Excel automation. Day 2 takes you deeper, with advanced automation techniques, error handling, email and PDF automation, and modular workflow design. By the end, you’ll have practical, industry-ready skills to automate repetitive tasks, optimize workflows, and boost productivity with UiPath",
        link: "https://forms.gle/BpjKcs9XZG4rvncq9",
        day1: {
          date: "06 Nov 2024",
          time: "Full Day",
          mode: "OFFLINE",
          venue: "1682, 1st Floor, SEC",
          resource: [
            {
              name: "Mr. Mohamed Salman",
              desig: "Automation Consultant, Northern Trust Asset Management",
            }, 
          ],
          staff: [
            {
              name: " Dr. R. Augustian Isaac",
              phone: 6383931678,
            },
          ],
          student: [
            {
              name: " Harini R",
              phone: 8668059418,
            },
            {
              name: " Aswini M",
              phone: 7338995840,
            },
            {
              name: " Nalini P",
              phone: 9344763202,
            },
            {
              name: " Bhavyashree R",
              phone: 9345850687,
            },
          ],
        },  
        day2: {
          date: "07 Nov 2024",
          time: "Full Day",
          mode: "OFFLINE",
          venue: "1682, 1st Floor, SEC",
          resource: [
            {
              name: "Mr. Mohamed Salman",
              desig: "Automation Consultant, Northern Trust Asset Management",
            }, 
          ],
          staff: [
            {
              name: " Dr. R. Augustian Isaac",
              phone: 6383931678,
            },
          ],
          student: [
            {
              name: " Harini R",
              phone: 8668059418,
            },
            {
              name: " Aswini M",
              phone: 7338995840,
            },
            {
              name: " Nalini P",
              phone: 9344763202,
            },
            {
              name: " Bhavyashree R",
              phone: 9345850687,
            },
          ],
        },    
      },//9

      {
        name: "AI - Driven Filmmaking",
        logo: "EventsAssets/driven.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: " 09:30 AM to 03:00 PM",
        note:"Idea Generation & Screenplay: AI assists in story ideas, dialogue, and plot development. Animation & VFX: AI speeds up animation and VFX with automation and realistic simulations. Sound & Video Editing: AI enhances sound syncing, transitions, and effects for efficient editing",
        link: "https://forms.gle/WXmfCiGVHaH5xAKw5",
        day1: {
          date: "07 Nov 2024",
          time: "09:30 AM to 03:00 PM",
          mode: "OFFLINE",  
          venue: "1511, 1st Floor, SEC",
          resource: [
            {
              name: " Mr. S. N. Sathva Krishnan",
              desig: "Head of the Visual Effects department at ICAT Design & Media College",
            },
            
          ],
          staff: [
            {
              name: " Mrs. J. Dhanalakshmi",
              phone: 8866426107,
            },
          ],
          student: [
            {
              name: " Deepika S",
              phone: 8248425226,
            },
            {
              name: " Vasundra Sri R",
              phone: 7806983505,
            },
            {
              name: " Jyesvanthe V",
              phone: 8148941952,
            },
            {
              name: " Sanjay Kumar N B",
              phone: 9677179915,
            },
          ],
        },      
      },//10

      {
        name: "Future Forage Gate",
        logo: "EventsAssets/futureforage.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: " 09:00 AM to 03:00 PM",
        note:"This workshop aims to provide students with valuable insights into options for higher studies after graduation, followed by skill-building sessions focused on quantitative and verbal reasoning. The schedule is structured to offer both guidance and practice in essential aptitude skills",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSdk_C95s4jjfrklM_5RcjDPuEukz2NeqJ68Mi3SsYbxW2_ZoQ/viewform",
        
        day1: {
          date: "06 Nov 2024",
          time: "09:00 AM to 03:00 PM",
          mode: "Individual",
          venue: "1611, 1st Floor, SEC",
          resource: [
            {
              name: " Mr. Krishnan Kesavan",
              desig: "Head of Operations and English language mentor, IMS Anna Nagar, Chennai",
            },
            {
              name: " Mr. Vijay Kumar",
              desig: "Quants mentor, IMS Anna Nagar, Chennai",
            },
            
          ],
          staff: [
            {
              name: " Mrs. E. Poongulali",
              phone:  9442787826,
            },
          ],
          student: [
            {
              name: " Nandhini E",
              phone:  8248451017,
            },
            {
              name: " Keerthana Jayasri S K",
              phone: 9342565093,
            },
            {
              name: " Taranikka A ",
              phone: 9585675901,
            },
            {
              name: " Vedishree G",
              phone: 9003926730,
            },
          ],
        },      
      },//11

      {
        name: "Full Stack Mastery - Idea to Deployment",
        logo: "EventsAssets/fullstack.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: "09:00 AM to 03:00 PM",
        note:"The Full Stack Mastery Deployment Workshop is a high-energy, immersive experience that takes you through the world of full stack web development using industry-leading technologies like React js, Node js, MongoDB Atlas, and Livewire. This workshop is designed to equip participants with the essential skills and insider knowledge needed to become a full stack development pro, all while exploring future trends and career possibilities",
        link: "https://forms.gle/1E6frBz2rE8MCKke6",
        day1: {
          date: "06 Nov 2024",
          time: "09:00 AM to 03:00 PM",
          mode: "Individual",
          venue: "1482 (IOT Lab), 1st Floor, SEC",
          resource: [
            {
              name: "Mr. K. Anupriyan ",
              desig: "B.TECH IT, M.E CSE, Technical Leader-Livewire Corporate,chennai",
            },
            
          ],
          staff: [
            {
              name: "Mrs. Shanmuga Priya",
              phone:  9094623070,
            },
          ],
          student: [
            {
              name: " Mamtha I",
              phone:  8838465440,
            },
            {
              name: " Kishore B",
              phone: 7550191229,
            },
            {
              name: " Akshayaa M T ",
              phone: 7010256205,
            },
            {
              name: " Akshaya S K",
              phone: 8778410710,
            },
          ],
        },    
      },//12

      {
        name: "A Hands-On Workshop in Product Design, Full-Stack Development, and DevOps",
        logo: "EventsAssets/handson.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: " will be updated soon",
        time: " 09:00 AM 03:00 PM",
        note:"Laptop Required",
        link: "https://forms.gle/PBi6k2ppcLtViayr6",
        topic:"things to be included",
        note:"Product Design: Learn user-centered design, quick prototyping, and usability testing to create intuitive, user-friendly interfaces. Full-Stack Development: Hands-on with frontend (HTML, CSS, JavaScript) and backend (APIs, databases) development to build complete web applications. DevOps: Practice CI/CD, containerization with Docker, and scaling applications with Kubernetes for streamlined deployment and management",
        day1: {
          date: "05 Nov 2024",
          time: " 09:00 AM 03:00 PM",
          mode: "Individual",
          venue: "1512, 1st Floor, SEC",
          
          resource: [
            {
               name: " Mr. Dhananjay Kumar",
               desig: " Assistant Vice President, Citi Bank",
            },
            
          ],
          staff: [
            {
              name: " Mrs. G. Abinaya",
              phone:  9500206093,
            },
          ],
          student: [
            {
              name: " Kalpana S",
              phone:  9361081634,
            },
            {
              name: " Malarvizhi G",
              phone: 7708254194,
            },
            {
              name: " Sonu S ",
              phone: 9840916273,
            },
            {
              name: " Jaya Abirami S",
              phone: 8122934948,
            },
          ],
        },   
      },//13
      
      {
        name: "Boot Camp on Drone Technology",
        logo: "EventsAssets/bootcamp.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "08.00am - 03:00pm", 
        note: "All participants must bring Laptop. Conducted By Department of ECE, EEE, SCOFT [AIDS | AIML | CSE | IT]",
        link: "https://docs.google.com/forms/d/1bgT9d1CYHXoVtNS37p0mHhrZhgNdXthS0F1sGNr7wQE/viewform?edit_requested=true",
        day1: {
          date: "04 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "Lines Hall, 3rd Floor, SEC",
          topic:"Introduction to Drones – Overview of types, components, and applications.",
          resource: [
            {
              name: "Dr. K. Krishna Naik",
              desig: "Associate Professor & HOD, IIITDM, Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910",
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
           
          ],
        },
        day2: {
          date: "05 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "Majestorium Hall, 5th Floor, SEC",
          topic:"Flight Mechanics – Basics of aerodynamics, flight control, and stability.",
          resource: [
            {
              name: "Dr. K. V. Eswaramoorthy",
              desig: "Assistant Professor (Grade-1) IIITDM, Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910",
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
          ],
        },
        day3: {
          date: "06 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "will be updated soon",
          topic:"Programming and Sensors – Integrating sensors, GPS, and control software.",
          resource: [
            {
              name: "Dr. K. Nagaraju",
              desig: "Assistant Professor (Grade 1), IIITDM, Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910",
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
          ],
        },
        day4: {
          date: "07 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "will be updated soon",
          topic:"Autonomous Navigation – Path planning, obstacle detection, and AI for autonomy.",
          resource: [
            {
              name: "Mr. D. Akhil Kumar",
              desig: "Junior Research Fellow,  IIITDM,  Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910",
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
          ],
        },
        day5: {
          date: "08 Nov 2024",
          mode: "Individual",
          time: "08.00am - 03:00pm",
          venue: "will be updated soon",
          topic:"Safety and Regulations – Key rules, privacy, and ethical considerations.",
          resource: [
            {
              name: "Ms. A. Sai Jahnavi",
              desig: "Junior Research Fellow, IIITDM,  Kurnool",
            },
          ],
          staff: [
            {
              name: "Dr. Raji Pandurangan",
              phone: 9003051625,
            },
            {
              name: "Dr. J. Anish Kumar",
              phone: "9842336137, 8072747910" ,
            },
          ],
          student: [
            {
              name: "Esaki Muthu M",
              phone: 7358596661,
            },
            {
              name: "Ganesh K",
              phone: 7358132832,
            },
            {
              name: "Arjun N S",
              phone: 7845904452,
            },
            {
              name: "Kishore Narayanan",
              phone: 9176125008,
            },
          ],
        },
      },//14
      

      {
        name: "Dive into Deep Learning",
        logo: "EventsAssets/diveinto.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "09:00am - 03:00pm", 
        link: "https://docs.google.com/forms/u/0/d/1WZgsNLX5zR0sGSL1YEG_trUjmYEEIi0fnmnDC15PqQg/viewform?edit_requested=true",
        day1: {
          date: "06 Nov 2024",
          mode: "Individual",
          time: "09:00am - 03:00pm",
          venue: "1681, 1st Floor, SEC",
          resource: [
            {
              name: "Mr. Amith Kumar",
              desig: "Senior Analyst",
            },
          ],
          staff: [
            {
              name: "Mrs. E. Poongulali",
              phone: 9442787826,
            },
          ],
          student: [
            {
              name: "Richardson A",
              phone: 7348890210,
            },
            {
              name: "Kishore Kumar U",
              phone: 6383179836,
            },
            {
              name: "Giftsoin N",
              phone: 9042445715,
            },
            {
              name: "Andrew Varghese ",
              phone: 8610594447,
            },
          ],
        },
        day2: {
          date: "07 Nov 2024",
          mode: "Individual",
          time: "09:00am - 03:00pm",
          venue: "1681, 1st Floor, SEC",
          resource: [
            {
              name: "Mr. Amith Kumar",
              desig: "Senior Analyst",
            },
          ],
          staff: [
            {
              name: "Mrs. E. Poongulali",
              phone: 9442787826,
            },
          ],
          student: [
            {
              name: "Richardson A",
              phone: 7348890210,
            },
            {
              name: "Kishore Kumar U",
              phone: 6383179836,
            },
            {
              name: "Giftsoin N",
              phone: 9042445715,
            },
            {
              name: "Andrew Varghese ",
              phone: 8610594447,
            },
          ],
        },
      },//15

      {
        name: "Laptop Diagnostics Mastery Workshop ",
        logo: "EventsAssets/laptopdi.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "09:00am - 12:00pm", 
        link: "https://docs.google.com/forms/d/e/1FAIpQLSdtLkh-vzOAEzqFR20zkZGGzmv3v2csVgm5vta54pRgnLcmNA/viewform",
        day1: {
          date: "04 Nov 2024",
          mode: "Individual",
          time: "09:00am - 03:00pm",
          venue: "1512, 1st Floor, SEC",
          resource: [
            {
              name: "Mr. R. M. Selvakumar",
              desig: "MD & CEO, Connect Infosystems",
            },
          ],
          staff: [
            {
              name: "Mrs. J. Dhanalakshmi",
              phone: 8866426107,
            },
          ],
          student: [
            {
              name: "Harish Ragavendra S",
              phone: 9486844192,
            },
            {
              name: "Sanjay S",
              phone: 9344911465,
            },
            {
              name: "Afifa A",
              phone: 9344529859,
            },
            {
              name: "Kaviya Sneka M",
              phone: 6380908470,
            },
          ],
        },
      },//16

      {
        name: "Advanced Excel and Power BI for Data Analytics",
        logo: "EventsAssets/dataanalytics.webp",
        tag: "WORKSHOP",
        color: "0, 70, 90",
        venue: "Will Be Updated Soon",
        time: "09:00am - 03:00pm", 
        note : "This workshop focuses on Advanced Excel and Power BI for Data Analytics, guiding participants from foundational data analysis concepts to creating insightful, interactive dashboards. Participants will learn advanced data cleaning and transformation techniques, visualizations, and dashboard creation. By the end of the workshop, they will be equipped with comprehensive skills in Excel and Power BI, empowering them to handle complex datasets and derive valuable insights from data. Hands-on sessions to develop practical skills in data analytics using Excel and Power BI. Participants will gain essential tools for creating data-driven insights",
        link: "https://forms.gle/hk4kszv5Uyoyxoc26",
        day1: {
          date: "06 Nov 2024",
          mode: "Individual",
          topic: "Focus on Advanced Excel for data cleaning, visualization, and interactive dashboards",
          time: "09:00am - 03:00pm",
          venue: "1481, 1st Floor, SEC",
          resource: [
            {
              name: "Dr. S. Stephen Bernard",
              desig: "Director of Learning and Development, Vinayak Institute of Training and Education",
            },
          ],
          staff: [
            {
              name: "Ms. S. P. Panimalar",
              phone: 9789703697,
            },
          ],
          student: [
            {
              name: "Dinesh Kumaraa K",
              phone: 8438923349,
            },
            {
              name: "Priyanka S",
              phone: 9150529822,
            },
          ],
        },
        day2: {
          date: "07 Nov 2024",
          mode: "Individual",
          time: "09:00am - 03:00pm",
          topic: "Focus on Power BI for data transformation, visualization, and dashboard creation",
          venue: "1481, 1st Floor, SEC",
          resource: [
            {
              name: "Dr. S. Stephen Bernard",
              desig: "Director of Learning and Development, Vinayak Institute of Training and Education",
            },
          ],
          staff: [
            {
              name: "Ms. S. P. Panimalar",
              phone: 9789703697,
            },
          ],
          student: [
            {
              name: "Dinesh Kumaraa K",
              phone: 8438923349,
            },
            {
              name: "Priyanka S",
              phone: 9150529822,
            },
          ],
        },
      },//17
    ],
  },//scoft

];